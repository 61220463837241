import type { GaVueComponent } from "@/common/vueUtils";
import { assert, assertIsDefined } from "@utils/assertion";
import type { DeepReadonly } from "vue";
import type { WithOptional } from "@utils/formUtils";
import "./_gaFormFieldOverlayInputSelect.scss";

export type SelectOption<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  value: T;
  options: DeepReadonly<SelectOption<T>[]>;
  onInputValue: (value: DeepReadonly<T>) => void;
  label?: string;
  name: string;
  help?: string;
  disabled: boolean;
  required: boolean;
  errors?: DeepReadonly<string[]>;
  displayValue?: string;
};

type PropsWithOptional<T> = WithOptional<Props<T>, "disabled" | "required">;

function addDefaults<T>(props: PropsWithOptional<T>): Props<T> {
  return {
    ...props,
    disabled: props.disabled ?? false,
    required: props.required ?? false,
  };
}

export function GaFormFieldOverlayInputSelect<T>(p: PropsWithOptional<T>): GaVueComponent {
  const props = addDefaults(p);
  const errors = props.errors ?? [];

  function onInput(event: Event): void {
    assert(event.target instanceof HTMLSelectElement);
    const value = props.options[event.target.selectedIndex];
    assertIsDefined(value);
    props.onInputValue(value.value);
  }

  return (
    <>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label}*
        </label>
      ) : null}
      <div class="form-select-container">
        <div class={{ "form-select-overlay": true }}>{props.displayValue}</div>
        <select
          class={{
            "form-control form-select": true,
            "is-invalid": errors.length > 0,
          }}
          name={props.name}
          onInput={onInput}
          disabled={props.disabled}
          required={props.required}>
          {props.options.map((option) => (
            <option selected={props.value === option.value}>{option.label}</option>
          ))}
        </select>
        {errors.map((error) => (
          <div class="invalid-feedback">{error}</div>
        ))}
        {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
      </div>
    </>
  );
}
