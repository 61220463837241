import { defineComponent, type PropType, ref } from "vue";
import { createStore, type Store } from "@/common/storeUtils";
import { type Language, type LeadForm, LeadFormCompanySize, LeadFormInquiry, type LeadFormProps, type ValidationErrorReasons } from "@newgenerated/shared/schema";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { getFieldError, resetFormValidation } from "@/common/formUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { addToast } from "@/components/general/GaToast";
import { submitLeadForm } from "@newgenerated/shared/stubs";
import { GaButton } from "@/components/general/GaButton";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaFormFieldInputSelect, type SelectOption } from "@/components/form/GaFormFieldInputSelect";
import { GaFormFieldTextArea } from "@/components/form/GaFormFieldTextArea";
import { assert, assertIsDefined, assertIsString } from "@utils/assertion";
import { getUtmData } from "@/components/leadform/_utm-extractor";
import { GaAlert } from "@/components/general/GaAlert";
import { RecaptchaForm } from "@/components/form/RecaptchaForm";
import { GaFormFieldOverlayInputSelect } from "@/components/form/GaFormFieldOverlayInputSelect";

type LeadFormEditState = { form: LeadForm; errors: ValidationErrorReasons };
type LeadFormState =
  | { kind: "LEAD_FORM"; state: LeadFormEditState }
  | { kind: "B2C_FORM"; state: LeadFormEditState }
  | { kind: "SUBMITTING"; state: LeadFormEditState }
  | { kind: "MEETING"; form: LeadForm; link: string }
  | { kind: "SUBMITTED" }
  | { kind: "SCHEDULED" };

async function saveLeadForm(state: Store<LeadFormState>, t: TranslationFn): Promise<void> {
  const { kind, store } = state.unpackUnion();

  assert(kind === "LEAD_FORM" || kind === "B2C_FORM" || kind === "SUBMITTING");

  state.set({ kind: "SUBMITTING", state: store.sub("state").get() });

  const formState = store.sub("state");

  formState.sub("errors").set({
    fields: { additionalProperties: {} },
    general: [],
  });
  try {
    const result = await submitLeadForm({ body: formState.sub("form").get() });
    if (result.kind === "success" && result.data.kind === "SUCCESS") {
      state.set({ kind: "SUBMITTED" });
    } else if (result.kind === "success" && result.data.kind === "MEETING") {
      state.set({ kind: "MEETING", form: formState.sub("form").get(), link: result.data.calendlyUrl });
    } else if (result.kind === "success" && result.data.kind === "ERROR") {
      formState.sub("errors").set(result.data.validationErrorReasons);
    } else {
      addToast({
        variant: "danger",
        autohide: false,
        message: t("general:error.default", ""),
      });
    }
  } catch (_) {
    addToast({
      variant: "danger",
      autohide: false,
      message: t("general:error.default", ""),
    });
  } finally {
    if (state.get().kind === "SUBMITTING" && formState.sub("form").sub("inquiry").get() === "FOR_INDIVIDUALS") state.set({ kind: "B2C_FORM", state: store.sub("state").get() });
    else if (state.get().kind === "SUBMITTING") state.set({ kind: "LEAD_FORM", state: store.sub("state").get() });
  }
}

const ValidatedGaFormFieldInputText = (props: { store: Store<LeadFormEditState>; fieldName: keyof LeadForm; label?: string; help?: string; placeholder?: string }): GaVueComponent => {
  const { store, fieldName, label, help, placeholder } = props;
  const field = store.sub("form").sub(fieldName);
  const errors = store.sub("errors");
  const fieldValue = field.get();
  assertIsString(fieldValue);
  return (
    <GaFormFieldInputText
      value={fieldValue}
      onUpdateValue={(value) => {
        field.set(value);
        resetFormValidation<LeadForm>(errors, fieldName);
      }}
      name={fieldName}
      label={`${label}*`}
      help={help}
      placeholder={placeholder}
      errors={getFieldError<LeadForm>(errors.get(), fieldName)}
    />
  );
};

export const CalendlyIframe = (props: { calendlyUrl: string; leadForm: LeadForm; onSchedule: () => void }): GaVueComponent => {
  const calendlyEmbedded = ref<HTMLDivElement | null>(null);
  const initializeWidget = (): void => {
    assertIsDefined(calendlyEmbedded.value);
    const url = new URL(props.calendlyUrl);
    url.searchParams.set("hide_gdpr_banner", "1");
    url.searchParams.set("hide_event_type_details", "1");
    url.searchParams.set("hide_landing_page_details", "1");

    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line no-undef
    Calendly.initInlineWidget({
      url: url.toString(),
      hideEventTypeDetails: true,
      parentElement: calendlyEmbedded.value,
      resize: true,
      prefill: {
        name: `${props.leadForm.firstName} ${props.leadForm.lastName}`,
        email: props.leadForm.email,
        customAnswers: {
          a1: `${props.leadForm.comment}`,
        },
      },
    });

    const handler = (e: MessageEvent): void => {
      if (e.origin === "https://calendly.com" && e.data.event === "calendly.event_scheduled") {
        props.onSchedule();
        window.removeEventListener("message", handler);
      }
    };

    window.addEventListener("message", handler);
  };

  return (
    <>
      <div ref={calendlyEmbedded} style="min-width:320px;height:700px;"></div>
      <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" onLoad={initializeWidget} async></script>
    </>
  );
};

const ValidatedGaFormFieldInputTextArea = (props: { store: Store<LeadFormEditState>; fieldName: keyof LeadForm; label?: string; required: boolean }): GaVueComponent => {
  const { store, fieldName } = props;
  const label = `${props.label}${props.required ? "*" : ""}`;
  const field = store.sub("form").sub(fieldName);
  const errors = store.sub("errors");
  const fieldValue = field.get();
  assertIsString(fieldValue);
  return (
    <GaFormFieldTextArea
      value={fieldValue}
      onUpdateValue={(value) => {
        field.set(value);
        resetFormValidation<LeadForm>(errors, fieldName);
      }}
      name={fieldName}
      label={`${label}`}
      errors={getFieldError<LeadForm>(errors.get(), fieldName)}
    />
  );
};

const GeneralFormError = (props: { errors: readonly string[] }): GaVueComponent | null => {
  return props.errors.length > 0 ? (
    <div class="my-3">
      <GaAlert variant="danger">
        {props.errors.map((error) => (
          <p vHtml={error} />
        ))}
      </GaAlert>
    </div>
  ) : null;
};

const IndividualFormFields = (props: { formState: Store<LeadFormEditState>; t: TranslationFn }): GaVueComponent => {
  const { t, formState } = props;

  return (
    <>
      <div class="col-6">
        <ValidatedGaFormFieldInputText store={formState} fieldName="firstName" label={t("corpSolution:leadForm.firstName")} />
      </div>
      <div class="col-6">
        <ValidatedGaFormFieldInputText store={formState} fieldName="lastName" label={t("corpSolution:leadForm.lastName")} />
      </div>
      <div class="col-12">
        <ValidatedGaFormFieldInputText store={formState} fieldName="email" label={t("about:support.form.from")} />
      </div>
      <div class="col-12">
        <ValidatedGaFormFieldInputTextArea store={formState} fieldName="comment" label={t("corpSolution:leadForm.comment")} required={true} />
      </div>
    </>
  );
};

const LeadFormFields = (props: { leadFormProps: LeadFormProps; formState: Store<LeadFormEditState>; t: TranslationFn; lang: Language }): GaVueComponent => {
  const { t, leadFormProps, formState, lang } = props;
  const selectedCountry = leadFormProps.countries.find((c) => c.countryCode === formState.sub("form").sub("country").get());

  const teamSizeOptions = (): SelectOption<LeadFormCompanySize>[] => {
    return LeadFormCompanySize.getValues().map((companySize) => {
      switch (companySize) {
        case "PLEASE_CHOOSE":
          return { label: t("general:pleaseChoose", ""), value: companySize };
        case "1_10":
          return { label: t("corpSolution:leadForm.companySize.1_10", companySize), value: companySize };
        case "11_100":
          return { label: t("corpSolution:leadForm.companySize.11_100", companySize), value: companySize };
        case "101_500":
          return { label: t("corpSolution:leadForm.companySize.101_500", companySize), value: companySize };
        case "501_1000":
          return { label: t("corpSolution:leadForm.companySize.501_1000", companySize), value: companySize };
        case "1001_5000":
          return { label: t("corpSolution:leadForm.companySize.1001_5000", companySize), value: companySize };
        case "5000PLUS":
          return { label: t("corpSolution:leadForm.companySize.5000PLUS", companySize), value: companySize };
      }
    });
  };
  const countryOptions = (): SelectOption<string>[] => {
    return props.leadFormProps.countries.map((country) => {
      return {
        label: `${country.names[lang]} (${country.phonePrefix})`,
        value: country.countryCode,
      };
    });
  };
  return (
    <>
      <div class="col-12 col-md-6">
        <ValidatedGaFormFieldInputText store={formState} fieldName="firstName" label={t("corpSolution:leadForm.firstName")} />
      </div>
      <div class="col-12 col-md-6">
        <ValidatedGaFormFieldInputText store={formState} fieldName="lastName" label={t("corpSolution:leadForm.lastName")} />
      </div>
      <div class="col-12 col-md-6">
        <ValidatedGaFormFieldInputText store={formState} fieldName="company" label={t("corpSolution:leadForm.company")} />
      </div>

      <div class="col-12 col-md-6">
        <GaFormFieldInputSelect
          value={formState.sub("form").sub("companySize").get()}
          options={teamSizeOptions()}
          onInputValue={(value) => {
            formState.sub("form").sub("companySize").set(value);
            resetFormValidation<LeadForm>(formState.sub("errors"), "companySize");
          }}
          name="inquiry"
          label={t("corpSolution:leadForm.companySize") + "*"}
          errors={getFieldError<LeadForm>(formState.sub("errors").get(), "companySize")}
        />
      </div>
      <div class="col-12 col-md-6">
        <ValidatedGaFormFieldInputText store={formState} fieldName="function" label={t("corpSolution:leadForm.title")} placeholder={t("corpSolution:leadForm.title.placeholder")} />
      </div>
      <div class="col-12 col-md-6">
        <ValidatedGaFormFieldInputText store={formState} fieldName="email" label={t("corpSolution:leadForm.email")} />
      </div>

      <div class="col-auto">
        <GaFormFieldOverlayInputSelect
          value={formState.sub("form").sub("country").get()}
          displayValue={`${selectedCountry?.countryCode} (${selectedCountry?.phonePrefix})`}
          options={countryOptions()}
          onInputValue={(value) => {
            formState.sub("form").sub("country").set(value);
            resetFormValidation<LeadForm>(formState.sub("errors"), "country");
          }}
          label={t("corpSolution:leadForm.phone")}
          name="country"
          errors={getFieldError<LeadForm>(formState.sub("errors").get(), "country")}
        />
      </div>
      <div class="col">
        <label for="phone" class="form-label"></label>
        <GaFormFieldInputText
          value={formState.sub("form").sub("phone").get()}
          onUpdateValue={(value) => {
            formState.sub("form").sub("phone").set(value);
            resetFormValidation<LeadForm>(formState.sub("errors"), "phone");
          }}
          name="phone"
          errors={getFieldError<LeadForm>(formState.sub("errors").get(), "phone")}
        />
      </div>

      <div class="col-12">
        <ValidatedGaFormFieldInputTextArea store={formState} fieldName="howDidYouFindUs" label={t("corpSolution:leadForm.howDidYouHearFromUs")} required={true} />
      </div>
      <div class="col-12">
        <ValidatedGaFormFieldInputTextArea store={formState} fieldName="comment" label={t("corpSolution:leadForm.comment")} required={false} />
      </div>
    </>
  );
};

const LeadFormContent = (props: { store: Store<LeadFormState>; leadFormProps: LeadFormProps }): GaVueComponent => {
  const { kind, store } = props.store.unpackUnion();
  const { t, currentLanguage } = useGaContext();
  if (kind === "LEAD_FORM" || kind === "B2C_FORM" || kind === "SUBMITTING") {
    const inquiryOptions = (): SelectOption<LeadFormInquiry>[] => {
      return LeadFormInquiry.getValues().map((inquiry) => {
        switch (inquiry) {
          case "FOR_INDIVIDUALS":
            return { label: t("corpSolution:leadForm.inquiry.FOR_INDIVIDUALS", inquiry), value: inquiry };
          case "PLEASE_CHOOSE":
            return { label: t("general:pleaseChoose", ""), value: inquiry };
          case "FOR_ENTERPRISE":
            return { label: t("corpSolution:leadForm.inquiry.FOR_ENTERPRISE", inquiry), value: inquiry };
          case "FOR_TEAMS":
            return { label: t("corpSolution:leadForm.inquiry.FOR_TEAMS", inquiry), value: inquiry };
        }
      });
    };
    const formState = store.sub("state");

    async function protectedSubmit(token: string): Promise<void> {
      assert(kind === "B2C_FORM" || kind === "LEAD_FORM");
      assertIsDefined(token);
      assert(token.length > 0);
      formState.sub("form").sub("gRecaptchaResponse").set(token);
      await saveLeadForm(props.store, t);
    }

    const form = (
      <RecaptchaForm onSubmit={protectedSubmit}>
        <GeneralFormError errors={formState.sub("errors").sub("general").get()} />
        <div class="row g-3">
          <div class="col-12">
            <GaFormFieldInputSelect
              value={formState.sub("form").sub("inquiry").get()}
              options={inquiryOptions()}
              onInputValue={(value) => {
                formState.sub("form").sub("inquiry").set(value);
                resetFormValidation<LeadForm>(formState.sub("errors"), "inquiry");
              }}
              name="inquiry"
              label={t("corpSolution:leadForm.inquiry")}
              errors={getFieldError<LeadForm>(formState.sub("errors").get(), "inquiry")}
            />
          </div>
          {formState.sub("form").sub("inquiry").get() === "FOR_INDIVIDUALS" ? (
            <IndividualFormFields t={t} formState={store.sub("state")} />
          ) : (
            <LeadFormFields lang={currentLanguage()} formState={store.sub("state")} leadFormProps={props.leadFormProps} t={t} />
          )}
          <div class="col-12 text-center">
            <GaButton>
              {formState.sub("form").sub("inquiry").get() === "PLEASE_CHOOSE" || formState.sub("form").sub("inquiry").get() !== "FOR_INDIVIDUALS" ? t("corpSolution:leadForm.bookAMeeting") : t("corpSolution:leadForm.submit")}
              {kind === "SUBMITTING" ? (
                <div class="spinner-border text-primary spinner-border-sm" style="color:#fff;">
                  <span></span>
                </div>
              ) : null}
            </GaButton>
          </div>
        </div>
      </RecaptchaForm>
    );

    const title = props.leadFormProps.title != null ? <h1>{props.leadFormProps.title}</h1> : null;
    const lead = props.leadFormProps.lead != null ? <p class="lead">{props.leadFormProps.lead}</p> : null;

    return (
      <>
        {title != null || lead != null ? (
          <div class="text-center mb-5">
            {title} {lead}
          </div>
        ) : null}
        {props.leadFormProps.fullWidth ? (
          form
        ) : (
          <div class="row justify-content-center">
            <div class="col-lg-8">{form}</div>
          </div>
        )}
      </>
    );
  } else if (kind === "MEETING") {
    return (
      <>
        <div class="modal fade show d-block" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLiveLabel" aria-modal="true" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalLiveLabel">
                  {t("corpSolution:leadForm.schedule.title")}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    props.store.set({ kind: "SUBMITTED" });
                  }}></button>
              </div>
              <div class="modal-body">
                <CalendlyIframe
                  calendlyUrl={store.sub("link").get()}
                  leadForm={store.sub("form").get()}
                  onSchedule={() => {
                    props.store.set({ kind: "SCHEDULED" });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop"></div>
      </>
    );
  } else if (kind === "SCHEDULED") {
    return (
      <div class="text-center">
        <h2>{t("corpSolution:scheduled.title")}</h2>
        <p>{t("corpSolution:scheduled.subtitle")}</p>
      </div>
    );
  } else {
    return (
      <div class="text-center">
        <h2>{t("corpSolution:thankyou.title")}</h2>
        <p>{t("corpSolution:thankyou.enterprise.contact.subtitle")}</p>
      </div>
    );
  }
};

export const LeadFormWidget = defineComponent({
  props: {
    leadFormProps: {
      type: Object as PropType<LeadFormProps>,
      required: true,
    },
  },
  setup: (props) => {
    const utm = getUtmData();
    const store = createStore<LeadFormState>({
      kind: "LEAD_FORM",
      state: {
        form: {
          comment: "",
          company: "",
          country: props.leadFormProps.country.countryCode,
          firstName: "",
          email: "",
          function: "",
          howDidYouFindUs: "",
          inquiry: "PLEASE_CHOOSE",
          companySize: "PLEASE_CHOOSE",
          lastName: "",
          phone: "",
          leadSource: props.leadFormProps.leadSource,
          utmCampaign: utm.campaign,
          utmContent: utm.content,
          utmId: utm.id,
          utmMedium: utm.medium,
          utmSource: utm.source,
          utmTerm: utm.term,
          gRecaptchaResponse: "",
        },
        errors: {
          fields: { additionalProperties: {} },
          general: [],
        },
      },
    });

    return () => <LeadFormContent store={store} leadFormProps={props.leadFormProps} />;
  },
});
