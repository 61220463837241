import { defineComponent, nextTick, onBeforeMount, type PropType } from "vue";
import type { ContentMetadata, MIABPageProps, MIABQuestionMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaNumberedList } from "@/components/general/GaNumberedList";
import "./pageComponents/_miab.scss";
import type { Store } from "@/common/storeUtils";
import { createStore } from "@/common/storeUtils";
import { GaButton } from "@/components/general/GaButton";
import { GaSpinner } from "@/components/general/GaSpinner";
import { Header } from "@/components/miab/pageComponents/Header";
import { Feedback } from "@/components/miab/pageComponents/Feedback";
import { Language } from "@utils/type/type";
import { GaBadge } from "@/components/general/GaBadge";
import ContentCard from "@utils/vue-migration/component/cards/ContentCard.vue";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

type SummaryStatus = {
  show: boolean;
  loading: boolean;
};

export type DiscussState = {
  status: "VIEW" | "EDIT";
  questions: MIABQuestionMetadata[];
};

type MIABPageLoadedState = {
  kind: "LOADED";
  summaryStatus: SummaryStatus;
  bookmarked: boolean;
  discussState: DiscussState;
  t: TranslationFn;
};
type MIABPageState = MIABPageLoadedState | { kind: "LOADING" };

function openSummary(store: Store<SummaryStatus>): void {
  store.set({ show: true, loading: true });
  // eslint-disable-next-line no-restricted-syntax
  void nextTick(() => {
    scrollTo({ top: 0 });
  });
}

function Prepare(props: { summary: ContentMetadata; summaryStore: Store<SummaryStatus>; keyInsights: string[]; t: TranslationFn }): GaVueComponent {
  const t = props.t;
  return (
    <>
      <div class="mb-2">
        <GaBadge variant="orange-light">{t("miab:page.prepare.badge")?.toUpperCase()}</GaBadge>
      </div>
      <h2>{t("miab:page.prepare.title")}</h2>
      <p>
        <strong>{t("miab:page.prepare.description")}</strong>
      </p>
      <p>
        <span vHtml={t("miab:page.prepare.keyInsights", [props.summary.common.title])} />
      </p>
      <ul class="mb-4">
        {props.keyInsights.map((value) => (
          <li>{value}</li>
        ))}
      </ul>
      <p class="mb-0">
        <GaButton onClick={() => openSummary(props.summaryStore)}>{t("miab:page.prepare.openSummary")}</GaButton>
      </p>
    </>
  );
}

function Discuss(props: { questions: MIABQuestionMetadata[]; t: TranslationFn }): GaVueComponent {
  const t = props.t;
  return (
    <>
      <div class="mb-2">
        <GaBadge variant="orange-light">{t("miab:page.discuss.badge")?.toUpperCase()}</GaBadge>
      </div>
      <h2>{t("miab:page.discuss.title")}</h2>
      <p>
        <strong>{t("miab:page.discuss.description")}</strong>
      </p>
      <div class="miab-questions">
        <GaNumberedList items={props.questions.map((question) => ({ title: question.title, text: question.question }))} />
      </div>
    </>
  );
}

function Implement(props: { t: TranslationFn }): GaVueComponent {
  const t = props.t;
  return (
    <div>
      <div class="mb-2">
        <GaBadge variant={"orange-light"}>{t("miab:page.implement.badge")?.toUpperCase()}</GaBadge>
      </div>
      <h2>{t("miab:page.implement.title")}</h2>
      <p>
        <b>{t("miab:page.implement.description")}</b>
      </p>
      <ul class="miab-implement__list">
        {t("miab:page.implement.bulletList")
          ?.split("<br>")
          .map((value) => <li>{value}</li>)}
      </ul>
    </div>
  );
}

function RelatedSummaries(props: { relatedSummaries: ContentMetadata[]; t: TranslationFn }): GaVueComponent {
  const t = props.t;
  return (
    <>
      <h2 class="mb-4">{t("miab:page.relatedContent.title")}</h2>
      <div class="row">
        {props.relatedSummaries.map((summary) => (
          <div class="col-lg-6 col-12 mb-3">
            <ContentCard content={summary} layout="horizontal" />
          </div>
        ))}
      </div>
    </>
  );
}

export function Content(props: { pageProps: MIABPageProps; store: Store<MIABPageLoadedState> }): GaVueComponent {
  const link = `/${Language.getCurrentLanguage()}/summary/${props.pageProps.summary.contentId.id}?iframe=true&o_s=MIAB`;
  const t = props.store.sub("t").get();
  return (
    <div class="d-flex justify-content-center">
      {!props.store.sub("summaryStatus").get().show ? (
        <div>
          <div class="mb-4 card no-shadow miab-header">
            <Header bookmarkStore={props.store.sub("bookmarked")} pageProps={props.pageProps} t={t} />
          </div>
          <div class="mb-4 card no-shadow miab-section">
            <Prepare summary={props.pageProps.summary} keyInsights={props.pageProps.keyInsights} summaryStore={props.store.sub("summaryStatus")} t={t} />
          </div>
          <div class="mb-4 card no-shadow miab-section">
            <Discuss questions={props.pageProps.questions} t={t} />
          </div>
          <div class="mb-4 card no-shadow miab-section">
            <Implement t={t} />
          </div>
          {props.pageProps.prospectView ? null : (
            <div class="miab-spacing">
              <Feedback dataId={props.pageProps.summary.contentId.id} summaryTitle={props.pageProps.summary.common.title} feedback={props.pageProps.feedback} t={t} />
            </div>
          )}
          {props.pageProps.similarMiabs.length > 0 ? (
            <div class="mb-4 miab-section">
              <RelatedSummaries relatedSummaries={props.pageProps.similarMiabs} t={t} />
            </div>
          ) : (
            <div class="mb-5"></div>
          )}
        </div>
      ) : (
        <div class="miab-summary">
          <div class="card miab-summary__card">
            <div class="miab-summary__title-section">
              <h3 class="miab-summary__title-section-title">{t("miab:page.summaryIframe.title")}</h3>
              <GaButton onClick={() => props.store.sub("summaryStatus").sub("show").set(false)}>
                <i class="ico-close" />
                &nbsp;{t("general:button.close")}
              </GaButton>
            </div>
            {props.store.sub("summaryStatus").get().loading ? (
              <div class="d-flex justify-content-center mt-5">
                <GaSpinner />
              </div>
            ) : null}
            <iframe class={["miab-summary__iframe", props.store.sub("summaryStatus").get().loading ? "d-none" : ""]} src={link} width="100%" onLoad={() => props.store.sub("summaryStatus").sub("loading").set(false)} />
          </div>
        </div>
      )}
    </div>
  );
}

/**
 * Meeting in a box page
 */
export const MIABPage = defineComponent({
  props: {
    miabPageProps: {
      type: Object as PropType<MIABPageProps>,
      required: true,
    },
  },
  setup: (props) => {
    const { loadTranslation } = useGaContext();
    const store = createStore<MIABPageState>({
      kind: "LOADING",
    });
    onBeforeMount(async () => {
      store.set({
        kind: "LOADED",
        summaryStatus: {
          show: false,
          loading: false,
        },
        bookmarked: props.miabPageProps.bookmarked,
        discussState: {
          status: "VIEW",
          questions: props.miabPageProps.questions,
        },
        t: await loadTranslation(props.miabPageProps.summary.common.language),
      });
    });
    return () => {
      const loadedState = store.unpackUnion();
      return loadedState.kind === "LOADING" ? (
        <div class="miab" style="display: flex; justify-content: center; align-items: center; height: 1000px;">
          <GaSpinner />
        </div>
      ) : (
        <div class="miab">
          <Content pageProps={props.miabPageProps} store={loadedState.store} />
        </div>
      );
    };
  },
});
