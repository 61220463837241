import type { ContentTypeFilterProps } from "@/components/search/fullSearchStoreTypes";
import type { GaVueComponent } from "@/common/vueUtils";
import { Filter } from "@/components/search/Filter";
import { GaButton } from "@/components/general/GaButton";
import type { Store } from "@/common/storeUtils";
import { GaChip } from "@/components/general/GaChip";
import { FilterChip } from "@/components/search/FilterChip";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function ContentFilters(props: { filterStore: Store<ContentTypeFilterProps>; applyFilters: () => void }): GaVueComponent {
  const { t } = useGaContext();
  const formFilterStore = props.filterStore.sub("formFilters");
  return (
    <>
      <div class="d-flex flex-wrap gap-2 mb-3">
        <GaChip icon="ico-equalizer" type={"checkbox"} changeHandler={() => props.filterStore.sub("formFilterExtended").set(!props.filterStore.sub("formFilterExtended").get())}>
          {t("summary:filtersTitle")}
        </GaChip>
        {formFilterStore
          .get()
          .filter((filter) => filter.activeValues.length > 0)
          .map((filter) => formFilterStore.sub(formFilterStore.get().findIndex((f) => f.identifier === filter.identifier)))
          .map((filter) => (
            <FilterChip filter={filter} />
          ))}
      </div>
      <div class={["collapse", props.filterStore.sub("formFilterExtended").get() ? "show" : ""]}>
        <div>
          <div class="filtering-dropdown">
            <div class="filtering-dropdown__left-col">
              {formFilterStore
                .get()
                .filter((filter) => filter.priority === "PRIMARY")
                .map((filter) => formFilterStore.sub(formFilterStore.get().findIndex((f) => f.identifier === filter.identifier)))
                .map((filter) => (
                  <Filter filter={filter} />
                ))}
            </div>
            <div class="filtering-dropdown__right-col">
              {formFilterStore
                .get()
                .filter((filter) => filter.priority === "SECONDARY")
                .map((filter) => formFilterStore.sub(formFilterStore.get().findIndex((f) => f.identifier === filter.identifier)))
                .map((filter) => (
                  <Filter filter={filter} />
                ))}
            </div>
            <div>
              <GaButton onClick={props.applyFilters}>{t("general:button.apply")}</GaButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
