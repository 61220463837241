import type { GaVueComponent } from "@/common/vueUtils";
import { GaFeedback } from "@/components/general/GaFeedback";
import { giveFeedback } from "@generated/api/mIABControllerApi";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";

async function updateFeedback(isPositive: boolean, dataId: number): Promise<void> {
  await giveFeedback({ dataId, isPositive });
}

export function Feedback(props: { dataId: bigint; summaryTitle: string; feedback: boolean | null; t: TranslationFn }): GaVueComponent {
  const t = props.t;
  return (
    <GaFeedback
      title={t("miab:page.feedback.title", "")}
      positiveFeedbackText={t("miab:page.feedback.positive", "")}
      negativeFeedbackText={t("miab:page.feedback.negative", "")}
      changeHandler={(value) => updateFeedback(value, Number(props.dataId))}
      showMailFeedbackLink={true}
      mailBody={t("miab:page.feedback.mailBody", [props.summaryTitle], "")}
      mailTitle={t("miab:page.feedback.mailTitle", [props.summaryTitle], "")}
      value={props.feedback ?? undefined}
    />
  );
}
