/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type PeerInsightFormStep, parsePeerInsightFormStep, renderPeerInsightFormStep } from './peerInsightFormStep';
// @ts-ignore
import { type PeerInsightQuestionView, parsePeerInsightQuestionView, renderPeerInsightQuestionView } from './peerInsightQuestionView';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type ActionableReflectSectionProperties = {
    actionableId: number;
    actionableTitle: string;
    experienceFeedbackScore: number | null;
    actionableLanguage: Language;
    peerInsightProvidedAt: DateTime | null;
    strictPrivacy: boolean;
    socialSharing: boolean;
    questions: Array<PeerInsightQuestionView>;
    initialQuestionId: number;
    formSteps: Array<PeerInsightFormStep>;
    certificateId: string;
    customerId: number;
}

export const parseActionableReflectSectionProperties = (data: any): ActionableReflectSectionProperties => {
    return {
        actionableId: ensureNonNull<number>(data.actionableId, "actionableId is required"),
        actionableTitle: ensureNonNull<string>(data.actionableTitle, "actionableTitle is required"),
        experienceFeedbackScore: data.experienceFeedbackScore == null ? null : data.experienceFeedbackScore,
        actionableLanguage: Language.getLangByIsoCode(ensureNonNull<string>(data.actionableLanguage, "actionableLanguage is required")),
        peerInsightProvidedAt: data.peerInsightProvidedAt == null ? null : DateTime.fromISO(ensureNonNull<string>(data.peerInsightProvidedAt, " is required")),
        strictPrivacy: ensureNonNull<boolean>(data.strictPrivacy, "strictPrivacy is required"),
        socialSharing: ensureNonNull<boolean>(data.socialSharing, "socialSharing is required"),
        questions: ensureNonNull<Array<PeerInsightQuestionView>>(data.questions, "questions is required").map((value: any) => parsePeerInsightQuestionView(value)),
        initialQuestionId: ensureNonNull<number>(data.initialQuestionId, "initialQuestionId is required"),
        formSteps: ensureNonNull<Array<PeerInsightFormStep>>(data.formSteps, "formSteps is required").map((value: any) => parsePeerInsightFormStep(value)),
        certificateId: ensureNonNull<string>(data.certificateId, "certificateId is required"),
        customerId: ensureNonNull<number>(data.customerId, "customerId is required"),
    }
}

export const renderActionableReflectSectionProperties = (data: ActionableReflectSectionProperties): any => {
    return {
        actionableId: data.actionableId,
        actionableTitle: data.actionableTitle,
        experienceFeedbackScore: data.experienceFeedbackScore == null ? null : data.experienceFeedbackScore,
        actionableLanguage: ensureNonNull<Language>(data.actionableLanguage, "actionableLanguage is required"),
        peerInsightProvidedAt:data.peerInsightProvidedAt == null ? null :  ensureNonNull<DateTime>(data.peerInsightProvidedAt, "peerInsightProvidedAt is required").toUTC().toISO(),
        strictPrivacy: data.strictPrivacy,
        socialSharing: data.socialSharing,
        questions: ensureNonNull<Array<PeerInsightQuestionView>>(data.questions, "questions is required").map((value: any) => renderPeerInsightQuestionView(value)),
        initialQuestionId: data.initialQuestionId,
        formSteps: ensureNonNull<Array<PeerInsightFormStep>>(data.formSteps, "formSteps is required").map((value: any) => renderPeerInsightFormStep(value)),
        certificateId: data.certificateId,
        customerId: data.customerId,
    }
}



export type ACTIONABLE_LANGUAGE = "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" ;

