import type { SummaryCardDemoProps, ContentIxStatusSummary } from "@newgenerated/shared/schema";
import { assert } from "@utils/assertion";
import { SummaryCardMini } from "@/components/cards/SummaryCardMini";
import { DateTime } from "@utils/type/type";
import { createStore, type Store } from "@/common/storeUtils";
import { defineComponent, type PropType } from "vue";
import { SummaryCard } from "@/components/cards/SummaryCard";

function toggleBookmark(store: Store<DateTime | null>): void {
  store.set(store.get() === null ? DateTime.now() : null);
}

export const SummaryCardDefaultDemo = defineComponent({
  props: {
    summaryCardDemoProps: {
      type: Object as PropType<SummaryCardDemoProps>,
      required: true,
    },
  },
  setup(props) {
    const [summary1, summary2, summary3, summary4, summary5] = props.summaryCardDemoProps.summaryList;
    assert(summary1 !== undefined && summary2 !== undefined && summary3 !== undefined && summary4 !== undefined && summary5 !== undefined, "Expected 5 summaries for demo site.");

    const store = createStore<ContentIxStatusSummary>({
      contentType: "SUMMARY",
      contentId: { contentType: "SUMMARY", id: BigInt(0) },
      bookmarkedAt: null,
      consumedAt: DateTime.now(),
      customerId: 0,
      likedAt: null,
      readingProgress: 0,
    });

    return () => (
      <div class="ga-dynamic-grid summary-card-grid">
        <SummaryCard summary={summary1} ixStatus={store.get()} toggleBookmark={() => toggleBookmark(store.sub("bookmarkedAt"))} />
        <SummaryCard summary={summary2} params={new URLSearchParams({ st: "ACTIONABLE", si: "1" })} />
        <SummaryCard summary={summary3} link={new URL("/free-abstracts/123?u=gA_Demo", window.location.origin)} params={new URLSearchParams({ st: "ACTIONABLE", si: "1" })} />
        <SummaryCard summary={summary4} link={null} delete={() => {}} />
        <SummaryCard summary={summary5} />
      </div>
    );
  },
});

export const SummaryCardCompactDemo = defineComponent({
  props: {
    summaryCardDemoProps: {
      type: Object as PropType<SummaryCardDemoProps>,
      required: true,
    },
  },
  setup(props) {
    const [summary1, summary2, summary3, summary4, summary5] = props.summaryCardDemoProps.summaryList;
    assert(summary1 !== undefined && summary2 !== undefined && summary3 !== undefined && summary4 !== undefined && summary5 !== undefined, "Expected 5 summaries for demo site.");

    const store = createStore<ContentIxStatusSummary>({
      contentType: "SUMMARY",
      contentId: { contentType: "SUMMARY", id: BigInt(0) },
      bookmarkedAt: null,
      consumedAt: DateTime.now(),
      customerId: 0,
      likedAt: null,
      readingProgress: 0,
    });

    return () => (
      <div class="ga-dynamic-grid summary-card-grid">
        <SummaryCard summary={summary1} compact={true} ixStatus={store.get()} toggleBookmark={() => toggleBookmark(store.sub("bookmarkedAt"))} />
        <SummaryCard summary={summary2} compact={true} params={new URLSearchParams({ st: "ACTIONABLE", si: "1" })} />
        <SummaryCard summary={summary3} compact={true} link={new URL("/free-abstracts/123?u=gA_Demo", window.location.origin)} params={new URLSearchParams({ st: "ACTIONABLE", si: "1" })} />
        <SummaryCard summary={summary4} compact={true} link={null} delete={() => {}} />
        <SummaryCard summary={summary5} compact={true} />
      </div>
    );
  },
});

export const SummaryCardMiniDemo = defineComponent({
  props: {
    summaryCardDemoProps: {
      type: Object as PropType<SummaryCardDemoProps>,
      required: true,
    },
  },
  setup(props) {
    const [summary1, summary2, summary3, summary4, summary5] = props.summaryCardDemoProps.summaryList;
    assert(summary1 !== undefined && summary2 !== undefined && summary3 !== undefined && summary4 !== undefined && summary5 !== undefined, "Expected 5 summaries for demo site.");

    const store = createStore<ContentIxStatusSummary>({
      contentType: "SUMMARY",
      contentId: { contentType: "SUMMARY", id: BigInt(0) },
      bookmarkedAt: null,
      consumedAt: DateTime.now(),
      customerId: 0,
      likedAt: null,
      readingProgress: 0,
    });

    return () => (
      <div class="row gap-3">
        <div class="col">
          <SummaryCardMini summary={summary1} ixStatus={store.get()} toggleBookmark={() => toggleBookmark(store.sub("bookmarkedAt"))} />
        </div>
        <div class="col">
          <SummaryCardMini summary={summary2} params={new URLSearchParams({ st: "ACTIONABLE", si: "1" })} />
        </div>
        <div class="col">
          <SummaryCardMini summary={summary3} link={new URL("/free-abstracts/123?u=gA_Demo", window.location.origin)} params={new URLSearchParams({ st: "ACTIONABLE", si: "1" })} />
        </div>
        <div class="col">
          <SummaryCardMini summary={summary4} link={null} />
        </div>
        <div class="col">
          <SummaryCardMini summary={summary5} />
        </div>
      </div>
    );
  },
});
