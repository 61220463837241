import { ensureNonNull } from "@utils/assertion";
import { type ActionableActionbarProps } from "@newgenerated/shared/schema";
import { serialize_ActionableActionbarProps, deserialize_ActionableActionbarProps } from "@newgenerated/shared/mapper";

export { type ActionableActionbarProps } from "@newgenerated/shared/schema";
export const parseActionableActionbarProps = (data: unknown): ActionableActionbarProps => {
  return ensureNonNull(deserialize_ActionableActionbarProps(JSON.stringify(data)));
};
export const renderActionableActionbarProps = (data: ActionableActionbarProps): any => {
  return JSON.parse(serialize_ActionableActionbarProps(data));
};
