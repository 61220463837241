/*
 * General function that remove all origin-related parameters from the
 */

const parametersToListenTo = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_id"];
const urlCookieName = "__ga-utm-source";

export type UtmData = {
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
  id: string;
};

export const processUrl = (browserUrl: string): void => {
  try {
    const url = new URL(browserUrl);
    const urlContainsData = Array.from(url.searchParams.keys()).some((key) => parametersToListenTo.includes(key));
    if (urlContainsData) {
      document.cookie = `${urlCookieName}=${encodeURIComponent(browserUrl)}; path=/`;
    }
  } catch (_e) {
    // Ignore invalid urls
  }
};

export const getUtmData = (): UtmData => {
  const cookies = document.cookie.split("; ").reduce<Record<string, string>>((acc, cookie) => {
    const [name, value] = cookie.split("=");
    if (name === undefined || value === undefined || value === "") {
      return acc;
    }
    acc[name] = decodeURIComponent(value);
    return acc;
  }, {});

  const url = cookies[urlCookieName];
  if (url === undefined) {
    return {
      source: "",
      medium: "",
      campaign: "",
      term: "",
      content: "",
      id: "",
    };
  }

  const queryParams = new URL(url).searchParams;

  return {
    source: queryParams.getAll("utm_source").at(-1) ?? "",
    medium: queryParams.getAll("utm_medium").at(-1) ?? "",
    campaign: queryParams.getAll("utm_campaign").at(-1) ?? "",
    term: queryParams.getAll("utm_term").at(-1) ?? "",
    content: queryParams.getAll("utm_content").at(-1) ?? "",
    id: queryParams.getAll("utm_id").at(-1) ?? "",
  };
};

document.addEventListener("DOMContentLoaded", () => {
  processUrl(document.location.href);
});
