import { type ContentTypeProps, defaultAmount, type SearchProps } from "@/components/search/fullSearchStoreTypes";
import type { GaVueComponent } from "@/common/vueUtils";
import { LoadingComponent } from "@/components/search/LoadingComponent";
import { NoResults } from "@/components/search/NoResults";
import type { Store } from "@/common/storeUtils";
import { ContentTypeChildren } from "@/components/search/ContentTypeChildren";
import { resetFilter } from "@/components/search/SearchResults";
import { ContentFilters } from "@/components/search/ContentFilters";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function ContentType(props: { contentType: Store<ContentTypeProps>; searchProps: Store<SearchProps>; resetAllFilters: () => void; search: (searchTerm: string, softReload: boolean) => void }): GaVueComponent {
  const { t } = useGaContext();
  const isSelected = props.searchProps.sub("selectedContentType").get() === props.contentType.get().kind;
  const contentTypeProps = props.contentType.get();

  if (props.searchProps.sub("status").get() === "LOADING") {
    return <LoadingComponent items={3} itemHeight={props.contentType.get().kind === "SUMMARY" ? "10.1875rem" : "3.75rem"} withTitle={true} perRow={2} />;
  } else if (contentTypeProps.items.length > 0 || isSelected) {
    return (
      <div>
        <h2 class="mb-4">{contentTypeProps.title}</h2>
        {contentTypeProps.filterProps.formFilters.length > 0 && isSelected ? (
          <div class="mb-4">
            <ContentFilters filterStore={props.contentType.sub("filterProps")} applyFilters={() => props.search(props.searchProps.sub("searchTerm").get(), false)} />
          </div>
        ) : null}
        {contentTypeProps.items.length > 0 ? (
          <ContentTypeChildren contentType={props.contentType} searchProps={props.searchProps} search={() => props.search(props.searchProps.sub("searchTerm").get(), true)} />
        ) : props.searchProps.sub("selectedContentType").get() !== "ALL" ? (
          <div class="py-3">
            <NoResults resetFilters={() => (props.searchProps.sub("selectedContentType").get() === "ALL" ? props.resetAllFilters : resetFilter(props.contentType))} />
          </div>
        ) : null}
        {props.searchProps.sub("selectedContentType").get() === "ALL" && contentTypeProps.items.length > defaultAmount[contentTypeProps.kind] ? (
          <div class="mt-3">
            <a type="button" class="btn btn-alt-primary btn-sm fullSearch__moreResultsButton" onClick={() => props.searchProps.sub("selectedContentType").set(contentTypeProps.kind)}>
              {contentTypeProps.filterProps.formFilters.length > 0 ? t("search:page.results.showMoreFilters") : t("search:page.results.showMore")}
            </a>
          </div>
        ) : null}
      </div>
    );
  }
  return <></>;
}
