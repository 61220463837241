import { ensureNonNull } from "@utils/assertion";
import { type BookmarkResponse } from "@newgenerated/shared/schema";
import { serialize_BookmarkResponse, deserialize_BookmarkResponse } from "@newgenerated/shared/mapper";

export { type BookmarkResponse } from "@newgenerated/shared/schema";
export const parseBookmarkResponse = (data: unknown): BookmarkResponse => {
  return ensureNonNull(deserialize_BookmarkResponse(JSON.stringify(data)));
};
export const renderBookmarkResponse = (data: BookmarkResponse): any => {
  return JSON.parse(serialize_BookmarkResponse(data));
};
