import type { GaVueComponent } from "@/common/vueUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function NoResults(props: { resetFilters: () => void }): GaVueComponent {
  const { t } = useGaContext();
  return (
    <>
      <h3>{t("browse:explore.noResults.heading")}</h3>
      <div class="mb-3">
        {t("browse:explore.noResults.description", "")
          .split("<br>")
          .map((text) => (
            <p class="mb-0">{text}</p>
          ))}
      </div>
      <ol>
        <li>
          <a href="#" type="button" onClick={props.resetFilters}>
            {t("browse:explore.noResults.resetFilters")}
          </a>
        </li>
        <li>{t("browse:explore.noResults.adjustFilters")}</li>
      </ol>
    </>
  );
}
