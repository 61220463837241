import type { GaVueComponent } from "@/common/vueUtils";
import { GaProgressCircle } from "@/components/general/GaProgressCircle";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";

function getFormattedAmountOfPeers(amountOfPeers: number): string {
  if (amountOfPeers > 5) {
    const roundedValue = amountOfPeers - (amountOfPeers % 5);
    return roundedValue.toString();
  }
  return "5";
}

export function Progress(props: { progress: number; amountOfPeers: number; t: TranslationFn }): GaVueComponent {
  return (
    <div class="peerinsight__progress">
      <div class="peerinsight__progress-circle">
        <GaProgressCircle progress={props.progress} noProgressIcon={"ico-rocket"} progressCircleColor="var(--ga-green)" blankCircleColor={props.progress > 0 ? "var(--ga-success-100)" : undefined} />
      </div>
      <div>
        <h4 class="mb-0">{props.t("actionable:peerinsight.progress.title")}</h4>
        <p class="peerinsight__progress-text">{props.t("actionable:peerinsight.progress.description.certificate", [getFormattedAmountOfPeers(props.amountOfPeers)])}</p>
      </div>
    </div>
  );
}
