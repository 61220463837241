import { ensureNonNull } from "@utils/assertion";
import { type LeadFormProps } from "@newgenerated/shared/schema";
import { serialize_LeadFormProps, deserialize_LeadFormProps } from "@newgenerated/shared/mapper";

export { type LeadFormProps } from "@newgenerated/shared/schema";
export const parseLeadFormProps = (data: unknown): LeadFormProps => {
  return ensureNonNull(deserialize_LeadFormProps(JSON.stringify(data)));
};
export const renderLeadFormProps = (data: LeadFormProps): any => {
  return JSON.parse(serialize_LeadFormProps(data));
};
