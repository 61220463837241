import { type DeepReadonly, defineComponent, onMounted, type PropType } from "vue";
import { ExperienceFeedback } from "@/components/actionable/actionable-page/ExperienceFeedback";
import type { SectionOption } from "@/components/actionable/actionable-page/actionablePageStore";
import { type ComponentActions, type ComponentState, createStore } from "@/components/actionable/actionable-page/actionablePageStore";
import type { Language } from "@utils/type/type";
import { DateTime } from "@utils/type/type";
import type { GaVueComponent } from "@/common/vueUtils";
import "@/components/actionable/actionable-page/_actionableReflectSection.scss";
import { GaBadge } from "@/components/general/GaBadge";
import { GaSpinner } from "@/components/general/GaSpinner";
import { GaButton } from "@/components/general/GaButton";
import { PeerInsightOverlay } from "@/components/actionable/actionable-page/PeerInsightOverlay";
import { PeerInsightSection } from "@/components/actionable/actionable-page/PeerInsightSection";
import { Progress } from "@/components/actionable/actionable-page/Progress";
import { OPEN_CERTIFICATE_ANCHOR_ELEMENT_SELECTOR, OPEN_CERTIFICATE_POPUP_EVENT } from "@/components/actionable/actionable-page/ActionableActionbar";
import { PeerInsightOverlayChildren } from "@/components/actionable/actionable-page/PeerInsightOverlayChildren";
import { getActionableReflectSectionProperties } from "@generated/api/actionableReflectSectionApiControllerApi";
import { getFeedbacks, navigateForm, submitPeerInsightForm, toggleLike } from "@generated/api/peerInsightFeedbackControllerApi";
import { trackPeerInsightStarted } from "@generated/api/actionableCustomerIxApiControllerApi";
import type { ActionableReflectSectionProps } from "@newgenerated/shared/schema";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";

function formatPeerInsightProvidedAt(dateTime: DateTime, t: TranslationFn, language: Language): string | undefined {
  const date = dateTime.toLocaleString({ weekday: "long", day: "numeric", month: "long", year: "numeric" }, { locale: language });
  const time = dateTime.toLocaleString(DateTime.TIME_SIMPLE, { locale: language });
  return t("actionable:peerinsight.feedback.providedAt", [date, time]);
}

function getSectionOptions(options: DeepReadonly<SectionOption[]>, questionId: number): SectionOption {
  const result = options.find((option) => option.peerInsightQuestionId === questionId);
  if (result !== undefined) {
    return result;
  }
  throw new Error("Section not found");
}

function Content(props: { state: DeepReadonly<ComponentState>; actions: ComponentActions; props: ActionableReflectSectionProps }): GaVueComponent {
  if (props.state.status === "LOADING") {
    return (
      <div class="my-3">
        <GaSpinner />
      </div>
    );
  }
  const currentStepId = props.state.currentFormStepId;
  const currentFormStep = props.state.formSteps.find((step) => step.question.peerInsightQuestionId === currentStepId);
  return (
    <div>
      <PeerInsightOverlay
        open={props.state.overlayStatus !== "CLOSED"}
        close={() => props.actions.toggleOverlay("CLOSED")}
        t={props.state.t}
        navigateForm={props.actions.navigateForm}
        currentFormStep={currentFormStep}
        overlayStatus={props.state.overlayStatus}
        children={
          <PeerInsightOverlayChildren
            displayMode={props.state.overlayStatus}
            t={props.state.t}
            currentFormStep={currentFormStep}
            formSteps={props.state.formSteps}
            actions={props.actions}
            feedbackLength={props.state.peerInsightFeedbacks.length}
            progress={props.state.progress}
            actionableId={props.props.actionableId}
            isMapp={props.props.isMapp}
            properties={props.state.properties}
          />
        }
      />
      <div class="step3-content">
        {props.state.properties.peerInsightProvidedAt === null && !props.state.properties.strictPrivacy ? (
          <div>
            {props.state.progress > 0 && props.state.overlayStatus !== "FORM" && props.state.overlayStatus !== "ANIMATION" ? (
              <div class="mb-3">
                <Progress progress={props.state.progress} amountOfPeers={props.state.peerInsightFeedbacks.length} t={props.state.t} />
              </div>
            ) : (
              <p>{props.state.t("actionable:peerinsight.feedback.formIntro.certificate")}</p>
            )}
            <GaButton onClick={() => props.actions.toggleOverlay("FORM")}>{props.state.t("actionable:peerinsight.feedback.openForm")}</GaButton>
          </div>
        ) : null}
        {props.state.showCertificateDownloadButton ? (
          <div class="mt-3 mb-1">
            <GaButton onClick={() => props.actions.toggleOverlay("CERTIFICATE")}>{props.state.t("actionable:page.downloadCertificate")}</GaButton>
          </div>
        ) : null}
        {!props.state.properties.strictPrivacy && (props.state.properties.peerInsightProvidedAt !== null || props.state.overlayStatus !== "CLOSED") ? (
          <div>
            {props.state.properties.peerInsightProvidedAt !== null ? (
              <div class="mb-4 mt-3 peerinsight-dashboard__badge">
                <GaBadge variant={"success"} icon={"ico-info-circle"}>
                  {formatPeerInsightProvidedAt(props.state.properties.peerInsightProvidedAt, props.state.t, props.state.properties.actionableLanguage)}
                </GaBadge>
              </div>
            ) : null}
            {props.state.peerInsightFeedbacks.length > 0 ? (
              <div>
                <h3 class="mb-4">{props.state.t("actionable:peerinsight.feedback.subtitle")}</h3>
                {props.state.properties.questions
                  .filter((q) => !q.numeric && q.allowedInNonStrictPrivacy && q.key !== "SUCCESSES")
                  .map((question) =>
                    props.state.status !== "LOADING" ? (
                      <div class="mb-5">
                        <PeerInsightSection
                          question={question}
                          answers={[...props.state.peerInsightFeedbacks].filter((feedback) => feedback.question.peerInsightQuestionId === question.peerInsightQuestionId)}
                          t={props.state.t}
                          toggleIsOpen={() => props.actions.toggleSection(question.peerInsightQuestionId)}
                          feedbacksToShow={getSectionOptions(props.state.sectionOptions, question.peerInsightQuestionId).feedbackToShow}
                          showMore={() => props.actions.showMoreFeedbacks(question.peerInsightQuestionId)}
                          isOpen={getSectionOptions(props.state.sectionOptions, question.peerInsightQuestionId).isOpen}
                          toggleLike={props.actions.toggleLike}
                          freeActionableActive={props.props.freeActionableActive}
                        />
                      </div>
                    ) : null,
                  )}
              </div>
            ) : null}
          </div>
        ) : null}
        {!props.props.freeActionableActive && props.state.properties.peerInsightProvidedAt !== null ? (
          <div class="row justify-content-center mb-4 mb-3">
            <div class="col">
              <ExperienceFeedback
                value={props.state.experienceFeedbackScore}
                title={props.state.t("actionable:feedback.title", "")}
                subtitle={props.state.t("actionable:feedback.subtitle", "")}
                lowestOptionTag={props.state.t("actionable:feedback.lowestOptionTag", "")}
                highestOptionTag={props.state.t("actionable:feedback.highestOptionTag", "")}
                onChange={(score) => props.actions.submitFeedbackScore(props.props.actionableId, score)}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export const ActionableReflectSection = defineComponent({
  props: {
    actionableReflectSectionProps: {
      type: Object as PropType<ActionableReflectSectionProps>,
      required: true,
    },
  },
  setup: (props) => {
    const { loadTranslation } = useGaContext();
    const store = createStore(props.actionableReflectSectionProps, {
      getActionableReflectSectionProperties: (actionableId) => getActionableReflectSectionProperties(Number(actionableId)),
      getFeedbacks: (actionableId) => getFeedbacks(Number(actionableId)),
      toggleLike: toggleLike,
      navigateForm: navigateForm,
      submitPeerInsightForm: submitPeerInsightForm,
      loadTranslation: loadTranslation,
      trackPeerInsightStarted: (actionableId) => trackPeerInsightStarted(Number(actionableId)),
    });
    onMounted(() => {
      const element = document.querySelector(OPEN_CERTIFICATE_ANCHOR_ELEMENT_SELECTOR);
      if (element !== null) {
        element.addEventListener(OPEN_CERTIFICATE_POPUP_EVENT, () => {
          void store.actions.toggleOverlay("CERTIFICATE");
        });
      }
    });
    return () => <Content state={store.state()} actions={store.actions} props={store.props} />;
  },
});
