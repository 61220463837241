<template>
  <div :class="cardClasses" :data-ga-data-id="content.contentId.id" :lang="content.common.language">
    <div class="card-cover">
      <a :href="cardLink" :target="target" tabindex="-1" v-if="cardLink">
        <ContentCardCover :content="content" />
      </a>
      <ContentCardCover :content="content" v-else />
    </div>
    <ContentCardBody :content="content" :link="cardLink" />
    <ContentCardActions :content="content" :ix-status="ixStatus" :allow-delete="allowDelete" :allow-bookmark="allowDelete" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ContentMetadata, ContentIxStatus } from "@newgenerated/shared/schema";
import ContentCardCover from "@utils/vue-migration/component/cards/ContentCardCover.vue";
import ContentCardBody from "@utils/vue-migration/component/cards/ContentCardBody.vue";
import ContentCardActions from "@utils/vue-migration/component/cards/ContentCardActions.vue";

type Layout = "vertical" | "horizontal";

function getLink(path: string | null, additionalParams?: URLSearchParams): string | null {
  if (path === null) {
    return null;
  }
  let link: URL | null = null;
  try {
    link = new URL(path, location.href);
    if (additionalParams !== undefined) {
      for (const [key, value] of additionalParams) {
        link.searchParams.append(key, value);
      }
    }
    return link.href;
  } catch (_) {
    return null;
  }
}

export default defineComponent({
  components: {
    ContentCardActions,
    ContentCardBody,
    ContentCardCover,
  },
  props: {
    content: {
      type: Object as PropType<ContentMetadata>,
      required: true,
    },
    layout: {
      type: String as PropType<Layout>,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
    additionalParams: {
      type: Object as PropType<URLSearchParams>,
      required: false,
    },
    target: {
      type: String,
      required: false,
    },
    allowBookmark: {
      type: Boolean,
      required: false,
    },
    allowDelete: {
      type: Boolean,
      required: false,
    },
    ixStatus: {
      type: Object as PropType<ContentIxStatus>,
      required: false,
    },
  },
  setup: (props) => {
    const cardLink = getLink(props.link ?? props.content.common.link, props.additionalParams);
    const cardClasses = ["content-card", "content-card--" + props.layout, props.content.common.inactive ? "content-card--inactive" : "", cardLink === null || cardLink.trim() === "" ? "content-card--no-link" : ""];
    return {
      cardLink,
      cardClasses,
    };
  },
});
</script>
