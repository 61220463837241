import { useCustomModal } from "@/components/general/GaModal";
import type { UserPortal } from "@/components/actionable/actionable-page/ActionableActionbar";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { useEnrichUrl } from "@utils/routeUtils";
import { CSRF_TOKEN_HIDDEN_INPUT_ID, getCsrfToken } from "@utils/csrfUtils";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { GaFormFieldInputCheckbox, GaFormFieldInputCheckboxExperimental } from "@/components/form/GaFormFieldInputCheckbox";
import { GaButton } from "@/components/general/GaButton";
import type { ContentMetadataActionable } from "@newgenerated/shared/schema";
import { createStore } from "@/common/storeUtils";

export async function openActionableLoginModal(userPortal: UserPortal | null, userCustomerId: number | null, actionable: ContentMetadataActionable, actionableName: string): Promise<void> {
  const { t, hasFeature } = useGaContext();
  const hasActionables = await hasFeature("ACTIONABLE");
  const enrichUrl = useEnrichUrl;
  const csrfToken = await getCsrfToken();
  const getTermsOfUseUrl = (): string => enrichUrl(`/terms-of-use?popup=true${userPortal?.smb === false ? `&corpname=${encodeURIComponent(userPortal.username)}` : ""}`);
  const getPrivacyPolicyUrl = (): string => enrichUrl(`/privacy-policy/corporate?popup=true${userPortal?.smb === false ? `&corpname=${encodeURIComponent(userPortal.username)}` : ""}`);

  await useCustomModal()({
    body: () => (
      <div class="px-3 py-2">
        {userPortal === null ? <h2>{t("actionable:page.a3.noaccess.heading.b2c")}</h2> : hasActionables ? <h2>{t("customer:login.title")}</h2> : <h2>{t("actionable:page.a3.noaccess.heading.b2b")}</h2>}
        {userCustomerId === null && (userPortal === null || hasActionables) ? (
          <>
            <h3>{t("actionable:page.a4.noaccess.login")}</h3>
            <form action={enrichUrl("/login")} method="post" class="d-flex flex-column gap-2">
              <input type="hidden" name={CSRF_TOKEN_HIDDEN_INPUT_ID} value={csrfToken} />
              <input type="hidden" name="onSuccess" value={`/actionables/${actionableName}/${actionable.contentId.id}`} />
              <GaFormFieldInputText type="email" value="" onUpdateValue={() => {}} name="username" label={t("customer:login.username")} />
              <div>
                <GaFormFieldInputText type="password" value="" onUpdateValue={() => {}} name="password" label={t("customer:login.password")} />
                <p class="my-1" innerHTML={t("customer:login.password.forgotten", [enrichUrl("/reset-password/email")])}></p>
              </div>
              <GaFormFieldInputCheckbox checked={true} name="remember" onUpdateChecked={() => {}} label={t("customer:login.password.save")} />
              <div>
                <GaButton type="submit">{t("general:button.login")}</GaButton>
              </div>
            </form>
            {userPortal !== null && userPortal.canSubscribe ? (
              <>
                <div class="actionable-noaccess__or caption">{t("general:or")}</div>
                <h3>{t("aspSubscribe:signup.title")}</h3>
                <form action={enrichUrl("/signup/b2b")} method="post" class="d-flex flex-column gap-2">
                  <input type="hidden" name={CSRF_TOKEN_HIDDEN_INPUT_ID} value={csrfToken} />
                  <input type="hidden" name="onSuccess" value={`/actionables/${actionableName}/${actionable.contentId.id}`} />
                  <GaFormFieldInputText type="email" value="" onUpdateValue={() => {}} name="email" label={t("customer:login.username")} />
                  {userPortal.needsPrivacyPolicy ? (
                    <GaFormFieldInputCheckboxExperimental store={createStore(false)} name="acceptingTermsAndConditions" required={true} labelHtml={t("warmup2:profile.agreeTermsPolicy", [getTermsOfUseUrl(), getPrivacyPolicyUrl()])} />
                  ) : null}
                  <div>
                    <GaButton type="submit" variant="secondary">
                      {t("subscribe:confirmation.getStarted")}
                    </GaButton>
                  </div>
                </form>
              </>
            ) : null}
            {!hasActionables ? <div class="actionable-noaccess__or caption">{t("general:or")}</div> : null}
          </>
        ) : null}
        {!hasActionables && userPortal !== null ? (
          <>
            <h3>{t("actionable:page.a4.noaccess.requestaccess")}</h3>
            <div data-tf-live="01HAYH3TCS3FG5R7JG3P94XF6M" data-tf-hidden={`act_id=${actionable.contentId.id},corp_id=${userPortal.corporateId}${userCustomerId !== null ? `,cust_id=${userCustomerId}` : ""}`}></div>
            <script src="//embed.typeform.com/next/embed.js"></script>
          </>
        ) : !hasActionables ? (
          <>
            <h3>{t("actionable:page.a4.noaccess.waitlist")}</h3>
            <div data-tf-live="01HAYH261QG4HSXWAAB66RH9WW" data-tf-hidden={`act_id=${actionable.contentId.id}${userCustomerId !== null ? `,cust_id=${userCustomerId}` : ""}`}></div>
            <script src="//embed.typeform.com/next/embed.js"></script>
          </>
        ) : null}
      </div>
    ),
  });
}
