import { ensureNonNull } from "@utils/assertion";
import { type ActionableReflectSectionProps } from "@newgenerated/shared/schema";
import { serialize_ActionableReflectSectionProps, deserialize_ActionableReflectSectionProps } from "@newgenerated/shared/mapper";

export { type ActionableReflectSectionProps } from "@newgenerated/shared/schema";
export const parseActionableReflectSectionProps = (data: unknown): ActionableReflectSectionProps => {
  return ensureNonNull(deserialize_ActionableReflectSectionProps(JSON.stringify(data)));
};
export const renderActionableReflectSectionProps = (data: ActionableReflectSectionProps): any => {
  return JSON.parse(serialize_ActionableReflectSectionProps(data));
};
