<template>
  <!-- How do we handle the situation with customer in vue? And can the content really be empty like in the JSP-Tag -->
  <div class="card-actions" v-if="allowDelete || (content.contentType === 'SUMMARY' && allowBookmark)">
    <div class="card-actions-left" v-if="allowDelete">
      <GaActionButton variant="danger" :data-ga-dataid="content.contentId.id" data-ga-delete-action="">
        <i class="ico-trash-bin" />
      </GaActionButton>
    </div>
    <div class="card-actions-right" v-if="content.contentType === 'SUMMARY' && allowBookmark">
      <GaActionButton>
        <!-- TODO: implementation missing -->
        <i v-if="ixStatus !== undefined && ixStatus.bookmarkedAt !== null" class="ico-bookmark-fill"></i>
        <i v-else class="ico-bookmark"></i>
      </GaActionButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { ContentMetadata, ContentIxStatus } from "@newgenerated/shared/schema";
import GaActionButton from "@utils/vue-migration/component/general/GaActionButton.vue";

export default defineComponent({
  components: {
    GaActionButton,
  },
  props: {
    content: {
      type: Object as PropType<ContentMetadata>,
      required: true,
    },
    allowBookmark: {
      type: Boolean,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      required: true,
    },
    ixStatus: {
      type: Object as PropType<ContentIxStatus>,
      required: false,
    },
  },
  setup: () => {
    return {};
  },
});
</script>
