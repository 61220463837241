import { setExperienceFeedback } from "@generated/api/actionableCustomerIxApiControllerApi";
import type { DeepReadonly } from "vue";
import { ref } from "vue";
import { assert, assertIsDefined, assertIsNumber } from "@utils/assertion";
import type { PeerInsightFeedbackDashboardView } from "@generated/model/peerInsightFeedbackDashboardView";
import { ACTIONABLE_WIDTH_TOGGLE_EVENT, ACTIONABLE_WIDTH_TOGGLE_SELECTOR } from "@/components/actionable/actionable-page/_actionable-width-toggler";
import type { NavigationDirection } from "@generated/model/navigationDirection";
import type { PeerInsightFormStep } from "@generated/model/peerInsightFormStep";
import { AccessForbiddenException } from "@utils/type/exception";
import type { ActionableReflectSectionApiMethods } from "@/components/actionable/actionable-page/actionablePageStoreUtil";
import type { ActionableReflectSectionProperties } from "@generated/model/actionableReflectSectionProperties";
import type { ActionableReflectSectionProps } from "@newgenerated/shared/schema";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";

export type FormStepExtended = PeerInsightFormStep & { dirty: boolean };

export type OnUpdateFormValue = <T extends keyof FormStepExtended>(fieldName: T, newValue: FormStepExtended[T], questionId: number) => void;

export type OverlayStatus = "FORM" | "ANIMATION" | "CERTIFICATE" | "CLOSED";

export type ComponentActions = {
  submitFeedbackScore: (actionableId: bigint, score: number) => void;
  toggleSection: (questionId: number) => void;
  showMoreFeedbacks: (questionId: number) => void;
  toggleLike: (peerInsightFeedbackId: number) => Promise<void>;
  onUpdateFormValue: OnUpdateFormValue;
  toggleOverlay: (desiredStatus: OverlayStatus) => void;
  navigateForm: (direction: NavigationDirection) => void;
  submitForm: (animationDuration?: number) => Promise<void>;
  onSelectPredefinedAnswer: (suggestedAnswerId: number, suggestedAnswerText: string, peerInsightQuestionId: number) => void;
};

export type SectionOption = { peerInsightQuestionId: number; isOpen: boolean; feedbackToShow: number };

export type DynamicComponentState =
  | {
      status: "LOADING";
    }
  | {
      status: "LOADED";
      experienceFeedbackScore: number | null;
      properties: DeepReadonly<ActionableReflectSectionProperties>;
      peerInsightFeedbacks: PeerInsightFeedbackDashboardView[];
      /*
       * The translation function is in the state because the i18n setup is initialized a second time with the actionable language.
       * This returns a different t method than usual, which will always return translations in the actionable language.
       */
      t: TranslationFn;
      sectionOptions: SectionOption[];
      currentFormStepId: number;
      progress: number;
      formSteps: FormStepExtended[];
      // this is a temporary helper to show the "download certificate" button as long as the page is not refreshed.
      showCertificateDownloadButton: boolean;
    };

export type ComponentState = DynamicComponentState & {
  overlayStatus: OverlayStatus;
};

export function isFormStepEmpty(formStep: DeepReadonly<PeerInsightFormStep>): boolean {
  if (formStep.question.numeric && formStep.selectedScore !== null && formStep.selectedScore > 0) {
    return false;
  } else if (!formStep.question.numeric && (formStep.selectedSuggestedAnswers.length > 0 || (formStep.text.trim().length > 0 && formStep.text.length < 280))) {
    return false;
  }
  return true;
}

function getCurrentProgress(formSteps: FormStepExtended[], currentQuestionId: number): number {
  let progress = 0;
  formSteps.forEach((step) => {
    if (!isFormStepEmpty(step)) {
      progress += 15;
      if (step.question.peerInsightQuestionId !== currentQuestionId) {
        progress += 10;
      }
    }
  });
  // Usually, the user enters only 4 questions in the normal flow. But through back navigation all questions can be answered.
  return progress > 90 ? 90 : progress;
}

export function createStore(
  props: ActionableReflectSectionProps,
  apiMethods: ActionableReflectSectionApiMethods,
): {
  state: () => DeepReadonly<ComponentState>;
  actions: ComponentActions;
  props: ActionableReflectSectionProps;
} {
  const state = ref<ComponentState>({
    status: "LOADING",
    overlayStatus: "CLOSED",
  });
  const propertiesPromise = apiMethods.getActionableReflectSectionProperties(props.actionableId);
  const feedbackPromise = apiMethods.getFeedbacks(props.actionableId);
  void Promise.all([propertiesPromise, feedbackPromise]).then(async ([properties, feedbacks]) => {
    const t = await apiMethods.loadTranslation(properties.actionableLanguage);
    assert(state.value.status === "LOADING");
    state.value = {
      ...state.value,
      status: "LOADED",
      experienceFeedbackScore: properties.experienceFeedbackScore,
      t: t,
      peerInsightFeedbacks: feedbacks,
      properties: properties,
      sectionOptions: properties.questions
        .filter((q) => !q.numeric)
        .map((question) => {
          return {
            peerInsightQuestionId: question.peerInsightQuestionId,
            isOpen: true,
            feedbackToShow: 3,
          };
        }),
      formSteps: properties.formSteps.map((step) => {
        return {
          ...step,
          dirty: false,
        };
      }),
      currentFormStepId: properties.initialQuestionId,
      progress: 0,
      showCertificateDownloadButton: false,
    };
  });

  const actions: ComponentActions = {
    submitFeedbackScore: async (actionableId, score): Promise<void> => {
      assert(state.value.status === "LOADED");
      await setExperienceFeedback(Number(actionableId), score);
      state.value = { ...state.value, experienceFeedbackScore: score };
    },
    showMoreFeedbacks: (questionId: number) => {
      assert(state.value.status === "LOADED");
      const sectionOptions = [...state.value.sectionOptions];
      const section = sectionOptions.filterEquals("peerInsightQuestionId", questionId)[0];
      assertIsDefined(section);
      section.feedbackToShow = section.feedbackToShow + 3;
      state.value = { ...state.value, sectionOptions: sectionOptions };
    },
    toggleSection: (questionId: number) => {
      assert(state.value.status === "LOADED");
      const sectionOptions = [...state.value.sectionOptions];
      const section = sectionOptions.find((section) => section.peerInsightQuestionId === questionId);
      assertIsDefined(section);
      section.isOpen = !section.isOpen;
      state.value = { ...state.value, sectionOptions: sectionOptions };
    },
    toggleLike: async (peerInsightFeedbackId: number): Promise<void> => {
      assert(state.value.status === "LOADED");
      await apiMethods.toggleLike(peerInsightFeedbackId);
      const changedFeedbacks = [...state.value.peerInsightFeedbacks].map((feedback) =>
        feedback.peerInsightFeedbackId === peerInsightFeedbackId ? { ...feedback, liked: !feedback.liked, likeCount: !feedback.liked ? feedback.likeCount + 1 : feedback.likeCount - 1 } : feedback,
      );
      state.value = { ...state.value, peerInsightFeedbacks: changedFeedbacks };
    },
    onUpdateFormValue: (fieldName, newValue, questionId): void => {
      assert(state.value.status !== "LOADING");
      const formState = [...state.value.formSteps];
      const formStep = formState.find((step) => step.question.peerInsightQuestionId === questionId);
      assertIsDefined(formStep);
      formStep[fieldName] = newValue;
      if (fieldName === "text" && typeof newValue === "string") {
        formStep.dirty = newValue.length > 0;
      }
      state.value = { ...state.value, formSteps: formState, progress: getCurrentProgress(state.value.formSteps, state.value.currentFormStepId) };
    },
    toggleOverlay: (desiredStatus): void => {
      if (desiredStatus === "FORM") {
        void apiMethods.trackPeerInsightStarted(props.actionableId);
      }
      if ((state.value.overlayStatus === "CLOSED" && desiredStatus !== "CLOSED") || (state.value.overlayStatus !== "CLOSED" && desiredStatus === "CLOSED")) {
        const event = new Event(ACTIONABLE_WIDTH_TOGGLE_EVENT);
        const selector = document.querySelector(ACTIONABLE_WIDTH_TOGGLE_SELECTOR);
        assert(selector !== null);
        selector.dispatchEvent(event);
      }
      state.value = { ...state.value, overlayStatus: desiredStatus };
    },
    navigateForm: async (direction): Promise<void> => {
      assert(state.value.status !== "LOADING");
      const questionId = await apiMethods.navigateForm({ navigationDirection: direction, peerInsightQuestionId: state.value.currentFormStepId, peerInsightFormSteps: state.value.formSteps });
      state.value = { ...state.value, currentFormStepId: questionId };
      state.value = { ...state.value, progress: getCurrentProgress(state.value.formSteps, state.value.currentFormStepId) };
    },
    submitForm: async (animationDuration?: number) => {
      assert(state.value.status !== "LOADING");
      try {
        await apiMethods.submitPeerInsightForm({
          peerInsightFormSteps: state.value.formSteps,
          actionableId: Number(props.actionableId),
          strictPrivacyEnabled: state.value.properties.strictPrivacy,
          freeActionableActive: props.freeActionableActive,
        });
        actions.toggleOverlay("ANIMATION");
        setTimeout(() => {
          assert(state.value.status === "LOADED");
          actions.toggleOverlay("CERTIFICATE");
          state.value = { ...state.value, showCertificateDownloadButton: true };
        }, animationDuration ?? 3600);
        const properties = await apiMethods.getActionableReflectSectionProperties(props.actionableId);
        state.value = { ...state.value, properties: properties };
      } catch (e) {
        if (e instanceof AccessForbiddenException) {
          if (e.originalError !== undefined && e.originalError.response !== undefined && e.originalError.response.data instanceof Array && e.originalError.response.data.length > 0) {
            const firstField = e.originalError.response.data.at(0);
            assertIsDefined(firstField);
            assertIsNumber(firstField);
            state.value = { ...state.value, overlayStatus: "FORM", currentFormStepId: firstField };
          }
        }
      }
    },
    onSelectPredefinedAnswer: (suggestedAnswerId, suggestedAnswerText, peerInsightQuestionId): void => {
      assert(state.value.status !== "LOADING");
      const formSteps = [...state.value.formSteps];
      const step = formSteps.find((step) => step.question.peerInsightQuestionId === peerInsightQuestionId);
      assertIsDefined(step);
      if (step.selectedSuggestedAnswers.includes(suggestedAnswerId)) {
        step.selectedSuggestedAnswers.splice(step.selectedSuggestedAnswers.indexOf(suggestedAnswerId), 1);
        if (step.text.includes(suggestedAnswerText)) {
          step.text = step.text.replace(suggestedAnswerText + " ", "");
        }
      } else {
        step.selectedSuggestedAnswers.push(suggestedAnswerId);
      }
      if (!step.dirty && step.selectedSuggestedAnswers.filter((answer) => answer === suggestedAnswerId).length > 0) {
        step.text = step.text + suggestedAnswerText + " ";
      }
      state.value = { ...state.value, formSteps: formSteps, progress: getCurrentProgress(state.value.formSteps, state.value.currentFormStepId) };
    },
  };

  return {
    state: () => state.value,
    actions,
    props: props,
  };
}
