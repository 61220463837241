import type { GaVueComponent } from "@/common/vueUtils";
import type { PeerInsightFeedbackDashboardView } from "@generated/model/peerInsightFeedbackDashboardView";
import { PeerInsightAnswer } from "@/components/actionable/actionable-page/PeerInsightAnswer";
import { GaButton } from "@/components/general/GaButton";
import type { PeerInsightQuestionView } from "@generated/model/peerInsightQuestionView";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";

function Icon(props: { question: PeerInsightQuestionView }): GaVueComponent {
  const classes = ["peerinsight-section__icon"];
  if (props.question.key === "ACTION") {
    classes.push("ico-people");
  } else if (props.question.key === "DIFFICULTIES") {
    classes.push("ico-flame");
  } else {
    classes.push("ico-graduation");
  }
  return (
    <div class="peerinsight-section__icon-wrap">
      <i class={classes} />
    </div>
  );
}

export function PeerInsightSection(props: {
  question: PeerInsightQuestionView;
  answers: PeerInsightFeedbackDashboardView[];
  t: TranslationFn;
  isOpen: boolean;
  toggleIsOpen: () => void;
  feedbacksToShow: number;
  showMore: () => void;
  toggleLike: (peerInsightFeedbackId: number) => Promise<void>;
  freeActionableActive: boolean;
}): GaVueComponent {
  return (
    <div>
      <h4 class="peerinsight-section__title" onClick={props.toggleIsOpen}>
        <Icon question={props.question} /> {props.t("actionable:peerinsight.feedback.title." + props.question.key.toLowerCase())}
        <div class="peerinsight-section__icon-chevron">
          <i class={props.isOpen ? "ico-chevron-up" : "ico-chevron-down"} />
        </div>
      </h4>
      <div class={!props.isOpen ? " peerinsight-section__content--hide" : ""}>
        <div class="peerinsight-section__answers">
          {props.answers
            .map((value) => (
              <div class="mb-3">
                <PeerInsightAnswer answer={value} t={props.t} toggleLike={props.toggleLike} freeActionableActive={props.freeActionableActive} />
              </div>
            ))
            .slice(0, props.feedbacksToShow)}
        </div>
        {props.answers.length > props.feedbacksToShow ? (
          <GaButton onClick={props.showMore} variant={"primary-alt"}>
            {props.t("actionable:peerinsight.feedback.showMore")}
          </GaButton>
        ) : null}
      </div>
    </div>
  );
}
