import type { GaVueComponent } from "@/common/vueUtils";
import type { FormStepExtended, OnUpdateFormValue } from "@/components/actionable/actionable-page/actionablePageStore";
import { isFormStepEmpty } from "@/components/actionable/actionable-page/actionablePageStore";
import { GaFormFieldTextArea } from "@/components/form/GaFormFieldTextArea";
import type { DeepReadonly, PropType } from "vue";
import { defineComponent, ref } from "vue";
import { GaButton } from "@/components/general/GaButton";
import type { NavigationDirection } from "@generated/model/navigationDirection";
import type { SuggestedAnswerView } from "@generated/model/suggestedAnswerView";
import { Progress } from "@/components/actionable/actionable-page/Progress";
import type { TranslationFn } from "@utils/vue-migration/common/gaContext/gaContextTranslations";

function getCharacterCountTest(characterCount: number, t: TranslationFn): string | undefined {
  const charactersLeft = 280 - characterCount;
  if (characterCount < 281) {
    return t("actionable:peerinsight.form.textarea.charactersLeft", [charactersLeft.toString()]);
  } else {
    return t("actionable:peerinsight.form.textarea.charactersLeft.overLimit", [charactersLeft.toString()]);
  }
}

function isFormStepDisabled(formStep: DeepReadonly<FormStepExtended>): boolean {
  return isFormStepEmpty(formStep) || (!formStep.question.numeric && (formStep.text.length > 280 || !formStep.dirty));
}

function Icon(props: { value: 1 | 2 | 3 | 4 | 5 | number }): GaVueComponent | null {
  switch (props.value) {
    case 1:
      return <i class="ico-emoji-worried" />;
    case 2:
      return <i class="ico-emoji-sad" />;
    case 3:
      return <i class="ico-emoji-neutral" />;
    case 4:
      return <i class="ico-emoji-smile" />;
    case 5:
      return <i class="ico-emoji-happy" />;
    default:
      return null;
  }
}

function BrSplit(props: { text: string }): GaVueComponent {
  return (
    <>
      {props.text.split("<br>").map((text) => (
        <p class="mb-0">{text}</p>
      ))}
    </>
  );
}

function NumericStep(props: { onUpdateValue: (selectedValue: number) => void; currentValue: number | null; t: TranslationFn }): GaVueComponent {
  return (
    <div class="peerinsight-form__numericScale">
      {Array.from({ length: 5 }, (_, i) => i + 1).map((count) => (
        <div class="d-flex justify-content-center">
          <span class={["peerinsight-form__numericScale-option", props.currentValue === count ? "peerinsight-form__numericScale-option--selected" : ""]} onClick={() => props.onUpdateValue(count)}>
            <Icon value={count} />
          </span>
        </div>
      ))}
      <span class="peerinsight-form__numericScale-labelLeft">
        <BrSplit text={props.t("actionable:peerinsight.form.numericScale.lowest", "")} />
      </span>
      <span class="peerinsight-form__numericScale-labelRight">
        <BrSplit text={props.t("actionable:peerinsight.form.numericScale.highest", "")} />
      </span>
    </div>
  );
}

const TextStep = defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    onUpdateTextValue: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    t: {
      type: Function as PropType<TranslationFn>,
      required: true,
    },
    suggestedAnswers: {
      type: Array as PropType<DeepReadonly<SuggestedAnswerView[]>>,
      required: true,
    },
    selectedSuggestedAnswers: {
      type: Array as PropType<DeepReadonly<number[]>>,
      required: true,
    },
    selectSuggestedAnswer: {
      type: Function as PropType<(suggestedAnswerId: number, suggestedAnswerText: string, peerInsightQuestionId: number) => void>,
      required: true,
    },
  },
  setup: (props) => {
    const textarea = ref<InstanceType<typeof HTMLTextAreaElement>>();

    function selectSuggestedAnswer(suggestedAnswerId: number, description: string, peerInsightQuestionId: number): void {
      props.selectSuggestedAnswer(suggestedAnswerId, description, peerInsightQuestionId);
      if (textarea.value !== undefined) {
        textarea.value.focus();
      }
    }

    return () => (
      <div>
        {props.suggestedAnswers.length > 0 ? (
          <div class="d-flex flex-wrap gap-2 mt-2 mb-4">
            {props.suggestedAnswers.map((suggestedAnswer) => {
              {
                const suggestedAnswerId = suggestedAnswer.suggestedAnswerId;
                if (suggestedAnswerId !== null) {
                  const isSelected = props.selectedSuggestedAnswers.filter((answer) => answer === suggestedAnswerId).length > 0;
                  return (
                    <span class={["peerinsight-form__pill", isSelected ? "peerinsight-form__pill--selected" : ""]} onClick={() => selectSuggestedAnswer(suggestedAnswerId, suggestedAnswer.description, suggestedAnswer.peerInsightQuestionId)}>
                      {suggestedAnswer.title}
                      {isSelected ? <i class="ico-close ms-1" /> : null}
                    </span>
                  );
                }
                return null;
              }
            })}
          </div>
        ) : null}
        {props.selectedSuggestedAnswers.length > 0 ? (
          <div class="peerinsight-form__textarea-completeSentence">
            <i class="ico-quotation-mark-99-fill" />
            <p class="text-start">{props.t("actionable:peerinsight.form.textarea.completeSentence")}</p>
          </div>
        ) : null}
        <div class="peerinsight-form__textarea">
          <GaFormFieldTextArea ref={textarea} placeholder={props.placeholder} value={props.value} onUpdateValue={(value) => props.onUpdateTextValue(value)} name="text" />
          <span class="peerinsight-form__textarea-counter">{getCharacterCountTest(props.value.length, props.t)}</span>
          <span class={["peerinsight-form__textarea-counter", props.value.length > 280 ? "peerinsight-form__textarea-counter--overLimit" : ""]}>{getCharacterCountTest(props.value.length, props.t)}</span>
        </div>
        <div class="peerinsight-form__textarea-hint">
          <i class="ico-info-circle" /> {props.t("actionable:peerinsight.form.textarea.hint")}
        </div>
      </div>
    );
  },
});

export function PeerInsightFeedbackForm(props: {
  currentFormStep: DeepReadonly<FormStepExtended>;
  progress: number;
  amountOfPeers: number;
  t: TranslationFn;
  onUpdateValue: OnUpdateFormValue;
  selectSuggestedAnswer: (suggestedAnswerId: number, suggestedAnswerText: string, peerInsightQuestionId: number) => void;
  navigateForm: (direction: NavigationDirection) => void;
  submitForm: () => void;
  formStepTitle: string;
}): GaVueComponent {
  return (
    <div class="peerinsight-form text-center">
      <div class="mb-3">
        <Progress progress={props.progress} amountOfPeers={props.amountOfPeers} t={props.t} />
      </div>
      <h3 class="peerinsight-form__questionTitle">{props.formStepTitle}</h3>
      <div class="mb-4 peerinsight-form__step">
        {props.currentFormStep.question.numeric ? (
          <NumericStep onUpdateValue={(value) => props.onUpdateValue("selectedScore", value, props.currentFormStep.question.peerInsightQuestionId)} currentValue={props.currentFormStep.selectedScore} t={props.t} />
        ) : (
          <TextStep
            value={props.currentFormStep.text}
            onUpdateTextValue={(value) => props.onUpdateValue("text", value, props.currentFormStep.question.peerInsightQuestionId)}
            placeholder={props.currentFormStep.question.showSuggestedAnswers ? props.t("actionable:peerinsight.form.textarea", "") : props.t("actionable:peerinsight.form.textarea.notOptional", "")}
            t={props.t}
            suggestedAnswers={props.currentFormStep.question.showSuggestedAnswers ? props.currentFormStep.suggestedAnswers : []}
            selectSuggestedAnswer={props.selectSuggestedAnswer}
            selectedSuggestedAnswers={props.currentFormStep.selectedSuggestedAnswers}
          />
        )}
      </div>
      <div class="peerinsight-form__navigationButton pb-3">
        <GaButton disabled={isFormStepDisabled(props.currentFormStep)} onClick={() => (props.currentFormStep.hasNext ? props.navigateForm("NEXT") : props.submitForm())}>
          {!props.currentFormStep.hasNext ? props.t("actionable:peerinsight.form.button.finish") : props.t("actionable:peerinsight.form.button.next")}
        </GaButton>
      </div>
    </div>
  );
}
