import { defineComponent, type PropType } from "vue";
import { GaFormFieldInputDate } from "@/components/form/GaFormFieldInputDate";
import type { LocalDate } from "@utils/type/LocalDate";
import { LocalDateUtils } from "@utils/type/LocalDate";
import { createStore, type Store } from "@/common/storeUtils";
import type { GaVueComponent } from "@/common/vueUtils";
import { DateTime } from "luxon";
import { DateTimeUtils } from "@utils/type/DateTimeUtils";
import type { DateFormattingDemoProps } from "@newgenerated/shared/schema";

function Row(props: { label: string; value: string }): GaVueComponent {
  return (
    <>
      <div class="col-3">{props.label}</div>
      <div class="col-9">{props.value}</div>
    </>
  );
}

function Formatting(props: { dateStore: Store<LocalDate | null> }): GaVueComponent {
  const date = props.dateStore.get();
  if (date !== null) {
    const dateTime = DateTime.utc(date.year, date.month, date.day, 14, 35, 12, 0);
    return (
      <>
        <GaFormFieldInputDate store={props.dateStore} name={"date"} label="Date to format" />
        <h3 class="mb-3">Date format</h3>
        <div class="row mb-4">
          <Row label="ISO format" value={LocalDateUtils.formatToIso(date)} />
          <Row label="English format" value={LocalDateUtils.formatWithLocale(date, "en")} />
          <Row label="German format" value={LocalDateUtils.formatWithLocale(date, "de")} />
          <Row label="French format" value={LocalDateUtils.formatWithLocale(date, "fr")} />
          <Row label="Spanish format" value={LocalDateUtils.formatWithLocale(date, "es")} />
          <Row label="Portugese format" value={LocalDateUtils.formatWithLocale(date, "pt")} />
          <Row label="Chinese format" value={LocalDateUtils.formatWithLocale(date, "zh")} />
          <Row label="Russian format" value={LocalDateUtils.formatWithLocale(date, "ru")} />
        </div>
        <h3 class="mb-3">Date and time format</h3>
        <div class="row mb-4">
          <Row label="ISO format" value={DateTimeUtils.formatUniversal(dateTime)} />
          <Row label="English format" value={DateTimeUtils.formatWithLocale(dateTime, "en")} />
          <Row label="German format" value={DateTimeUtils.formatWithLocale(dateTime, "de")} />
          <Row label="French format" value={DateTimeUtils.formatWithLocale(dateTime, "fr")} />
          <Row label="Spanish format" value={DateTimeUtils.formatWithLocale(dateTime, "es")} />
          <Row label="Portugese format" value={DateTimeUtils.formatWithLocale(dateTime, "pt")} />
          <Row label="Chinese format" value={DateTimeUtils.formatWithLocale(dateTime, "zh")} />
          <Row label="Russian format" value={DateTimeUtils.formatWithLocale(dateTime, "ru")} />
        </div>
      </>
    );
  }
  return <></>;
}

export const DateFormattingDemo = defineComponent({
  props: {
    dateFormattingDemoProps: {
      type: Object as PropType<DateFormattingDemoProps>,
      required: true,
    },
  },
  setup: (props) => {
    const dateStore = createStore<LocalDate | null>(LocalDateUtils.now());
    return () => (
      <div class="row">
        <div class="col-6">
          <h2 class="mb-3 mt-4">Vue</h2>
          <Formatting dateStore={dateStore} />
        </div>
        <div class="col-6">
          <h2 class="mb-3 mt-4">Java / Kotlin</h2>
          <div style="height:5.125rem;" />
          <h3 class="mb-3">Date java.time.format.DateTimeFormatter</h3>
          <div class="row mb-4">
            {props.dateFormattingDemoProps.values.slice(0, 8).map((value) => (
              <Row label={value.label} value={value.value} />
            ))}
          </div>
          <h3 class="mb-3">DateTime java.time.format.DateTimeFormatter</h3>
          <div class="row mb-4">
            {props.dateFormattingDemoProps.values.slice(8, 16).map((value) => (
              <Row label={value.label} value={value.value} />
            ))}
          </div>
        </div>
      </div>
    );
  },
});
