import { ensureNonNull } from "@utils/assertion";
import { AreaOfExpertise } from "@newgenerated/shared/schema";
import { serialize_AreaOfExpertise, deserialize_AreaOfExpertise } from "@newgenerated/shared/mapper";

export { AreaOfExpertise } from "@newgenerated/shared/schema";
export const parseAreaOfExpertise = (data: unknown): AreaOfExpertise => {
  return ensureNonNull(deserialize_AreaOfExpertise(JSON.stringify(data)));
};
export const renderAreaOfExpertise = (data: AreaOfExpertise): any => {
  return JSON.parse(serialize_AreaOfExpertise(data));
};
