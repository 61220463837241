import type { GaVueComponent } from "@/common/vueUtils";
import { NoResults } from "@/components/search/NoResults";
import { contentTypeOrder, type ContentTypeProps, type SearchProps } from "@/components/search/fullSearchStoreTypes";
import { ContentType } from "@/components/search/ContentType";
import type { Store } from "@/common/storeUtils";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function resetFilter(store: Store<ContentTypeProps>): void {
  const resettedFilter = store.get().filterProps.formFilters.map((filter) => ({ ...filter, activeValues: [] }));
  store.set({ ...store.get(), filterProps: { ...store.get().filterProps, formFilters: resettedFilter } });
}

export function resetAllFilters(store: Store<ContentTypeProps[]>): void {
  store.get().forEach((ct) => {
    resetFilter(store.sub(store.get().indexOf(ct)));
  });
}

function ErrorState(): GaVueComponent {
  const { t } = useGaContext();
  return (
    <div>
      <h2 class="h3">{t("search:page.search.errorTitle")}</h2>
      <p>{t("search:page.search.errorDescription")}</p>
    </div>
  );
}

export function SearchResults(props: { contentTypeProps: Store<ContentTypeProps[]>; searchProps: Store<SearchProps>; search: (searchTerm: string, softReload: boolean) => void }): GaVueComponent {
  const filteredContentTypes = props.contentTypeProps.get().filter((value) => props.searchProps.sub("selectedContentType").get() === "ALL" || props.searchProps.sub("selectedContentType").get() === value.kind);

  if (props.searchProps.sub("status").get() === "ERROR") {
    return <ErrorState />;
  }
  return (
    <>
      {props.contentTypeProps.get().some((ct) => ct.items.length > 0) || props.searchProps.sub("status").get() === "LOADING" ? (
        <>
          {filteredContentTypes
            .sort((a, b) => contentTypeOrder[a.kind] - contentTypeOrder[b.kind])
            .map((contentType) => (
              <div class="mb-4">
                <ContentType
                  contentType={props.contentTypeProps.sub(props.contentTypeProps.get().findIndex((c) => c.kind === contentType.kind))}
                  searchProps={props.searchProps}
                  search={props.search}
                  resetAllFilters={() => resetAllFilters(props.contentTypeProps)}
                />
              </div>
            ))}
        </>
      ) : (
        <div class="py-3">
          <NoResults
            resetFilters={() =>
              props.searchProps.sub("selectedContentType").get() === "ALL"
                ? resetAllFilters(props.contentTypeProps)
                : resetFilter(props.contentTypeProps.sub(props.contentTypeProps.get().findIndex((ct) => ct.kind === props.searchProps.sub("selectedContentType").get())))
            }
          />
        </div>
      )}
    </>
  );
}
