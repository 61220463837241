/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export namespace OutboundLinkType {
  const _values = [ "GENERIC", "BUYBOOK", "MIAB_SCHEDULE", "ACTIONABLE_SCHEDULE" ] as const;
  export type Impl = typeof _values[number];
  export function getValues() {
    return _values;
  }
}
export type OutboundLinkType = OutboundLinkType.Impl;

export const parseOutboundLinkType = (value: OutboundLinkType): OutboundLinkType => {
    return value;
}

export const renderOutboundLinkType = (value: OutboundLinkType): OutboundLinkType => {
    return value;
}
