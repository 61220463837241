import { defineComponent, onMounted, type PropType } from "vue";
import { createStore } from "@/common/storeUtils";
import { assert } from "@utils/assertion";
import { load, type ReCaptchaInstance } from "recaptcha-v3";

export const RecaptchaForm = defineComponent({
  props: {
    onSubmit: {
      type: Function as PropType<(token: string) => void>,
      required: true,
    },
  },

  setup: (props, { slots }) => {
    const store = createStore<ReCaptchaInstance | null>(null);

    onMounted(async () => {
      const recaptcha = await load("6Lf9SoAlAAAAAEamErYdj0HurO2nRxIk5UhQXjcW");
      store.set(recaptcha);
    });

    async function onSubmit(e: Event): Promise<void> {
      e.preventDefault();
      const recaptcha = store.get();
      assert(recaptcha !== null);
      const token = await recaptcha.execute();
      props.onSubmit(token);
    }

    return () => <form onSubmit={onSubmit}>{slots.default?.()}</form>;
  },
});
