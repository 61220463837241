import type { Language } from "@utils/type/type";
import type { DateTime } from "luxon";

const FORMAT_UNIVERSAL = "yyyy-MM-dd HH:mm" as const;
const FORMAT_EN_US = "MMM dd, yyyy, h:mm a" as const;
const FORMAT_DE_DE = "dd.MM.yyyy, HH:mm" as const;
const FORMAT_FR_FR = "d MMM yyyy HH:mm" as const;
const FORMAT_ES_MX = "d MMM. yyyy HH:mm" as const;
const FORMAT_PT_BR = "d 'de' MMM 'de' yyyy HH:mm" as const;
const FORMAT_ZH_CN = "yyyy'年'M'月'dd'日' ah:mm" as const;
const FORMAT_RU_RU = "d MMM yyyy г., HH:mm" as const;

type DateTimeFormat = typeof FORMAT_UNIVERSAL | typeof FORMAT_EN_US | typeof FORMAT_DE_DE | typeof FORMAT_FR_FR | typeof FORMAT_ES_MX | typeof FORMAT_PT_BR | typeof FORMAT_ZH_CN | typeof FORMAT_RU_RU;

const dateTimeFormats: { [lang in Language]: DateTimeFormat } = {
  en: FORMAT_EN_US,
  de: FORMAT_DE_DE,
  fr: FORMAT_FR_FR,
  es: FORMAT_ES_MX,
  pt: FORMAT_PT_BR,
  zh: FORMAT_ZH_CN,
  ru: FORMAT_RU_RU,
};

// Format, which can be used anywhere. Does not depend on the locale
const formatUniversal = (dateTime: DateTime): string => dateTime.toFormat(FORMAT_UNIVERSAL);

const formatWithLocale = (dateTime: DateTime, language: Language): string => {
  const format = dateTimeFormats[language];
  return dateTime.toFormat(format, { locale: language });
};

export const DateTimeUtils = {
  formatWithLocale,
  formatUniversal,
  FORMAT_UNIVERSAL,
};
