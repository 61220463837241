import { defineComponent, onMounted } from "vue";
import { GaFormFieldInputSelect, type SelectOption } from "@/components/form/GaFormFieldInputSelect";
import type { WarmupV2RelayType } from "@newgenerated/shared/schema";
import { DateTime } from "@utils/type/type";
import { createStore, type Store } from "@/common/storeUtils";
import { getLanguageOptions, getPushFrequencyOptions, loadCountryOptions, WarmupV2Component, type WarmupV2State } from "@/components/warmup/WarmupV2";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

const kindOptions: SelectOption<WarmupV2RelayType["kind"]>[] = [
  { value: "DEFAULT", label: "Default" },
  { value: "SUMMARY", label: "Summary" },
  { value: "ACTIONABLE", label: "Actionable" },
];

namespace RelayType {
  export function toDefault(store: Store<WarmupV2RelayType>): void {
    store.set({ kind: "DEFAULT", relayState: new URL("/en/demo", location.origin).href });
  }

  export function toSummary(store: Store<WarmupV2RelayType>): void {
    store.set({
      kind: "SUMMARY",
      summary: {
        dataId: 1,
        title: "The 10 Natural Laws of Successful Time and Life Management",
        subtitle: null,
        originalTitle: null,
        teaser: null,
        language: "en",
        sourceType: "BOOK",
        documentType: "ABSTRACT",
        canonicalName: "",
        coverUri: "/summary-img/2-JQS5X25C.jpg",
        rating: 6,
        authors: [],
        authorInfo: "Jogi Rippel und Scott Peltin",
        publisherName: null,
        publicationDate: null,
        publisherInfo: null,
        backgroundColor: null,
        bestseller: true,
        businessClassic: true,
        active: true,
        activatedAt: null,
        audioActive: true,
        audioActivatedAt: null,
        audioDuration: 1,
        previewAvailable: true,
        modifiedAt: null,
        duration: 1,
        coverModifiedAt: null,
        imageBorder: false,
        countriesExcluded: [],
        countriesIncluded: [],
        summaryPages: 1,
        summaryWords: 1,
        contentItemId: BigInt(1),
        contentItemType: "SUMMARY",
        activationTime: null,
        descriptionHtml: null,
        tags: [],
      },
      relayState: new URL("/summary/1", location.origin).href,
    });
  }

  export function toActionable(store: Store<WarmupV2RelayType>): void {
    store.set({
      kind: "ACTIONABLE",
      actionable: {
        actionableId: BigInt(1),
        name: "ttt",
        title: "Title of This Learning Experience Card",
        description: "",
        active: true,
        language: "en",
        coverUri: "/actionable-img/82-foster-an-organizational-learning-culture-1700131306000.jpg",
        activatedAt: null,
        modifiedAt: DateTime.now(),
        tags: [],
      },
      relayState: new URL("/actionables/ttt/1", location.origin).href,
    });
  }
}

export const WarmupV2Demo = defineComponent({
  setup() {
    const { t } = useGaContext();
    const store = createStore<WarmupV2State>({
      editForm: {
        firstName: "Gorgie",
        lastName: "Boy",
        email: "gorgieboy@atlassian.com",
        password: "",
        language: "en",
        countryCode: "US",
        frequency: "ONCE_A_WEEK",
        termsAndConditions: false,
      },
      metaForm: {
        changedFields: { kind: "FIELDS", fields: [] },
        errors: { general: [], fields: { additionalProperties: {} } },
      },
      submissionState: "IDLE",
      options: {
        languages: getLanguageOptions(t),
        countries: [],
        frequencies: getPushFrequencyOptions(t),
      },
    });

    const relay = createStore<WarmupV2RelayType>({
      kind: "DEFAULT",
      relayState: "",
    });

    onMounted(async () => {
      await loadCountryOptions(store.sub("options").sub("countries"));
    });

    return () => (
      <>
        <WarmupV2Component
          store={store}
          relay={relay.get()}
          corporationName="Deutsche Bank"
          evrUuid={crypto.randomUUID()}
          ssoRequest={{
            ssoUid: "demo",
            corporateId: 0,
            title: null,
            function: "",
            town: "",
          }}
        />
        <br class="m-5" />
        <div class="card p-2 border-dark" style="max-width: unset;">
          <GaFormFieldInputSelect
            options={kindOptions}
            name="toggleKind"
            value={relay.get().kind}
            onInputValue={(newKind) => {
              switch (newKind) {
                case "DEFAULT":
                  return RelayType.toDefault(relay);
                case "SUMMARY":
                  return RelayType.toSummary(relay);
                case "ACTIONABLE":
                  return RelayType.toActionable(relay);
              }
            }}
            label="Toggle type (Default, Summary, Actionable)"
          />
          {/* TODO: custom fields */}
          {/*<div class="my-3 ga-dynamic-grid" style="--ga-dynamic-grid--min-width: 15rem;">*/}
          {/*  <GaFormFieldInputCheckbox checked={opts.value.showCustomFields} onUpdateChecked={(newValue) => (opts.value.showCustomFields = newValue)} name="showCustomFields" label="Show Custom Fields (two steps)" />*/}
          {/*</div>*/}
          {/* TODO: Button which displays a modal */}
        </div>
      </>
    );
  },
});
