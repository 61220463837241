// @ts-ignore
import { hasProp, assert } from "@utils/assertion";
// @ts-ignore
import { isArray } from "@utils/arrayUtils";
// @ts-ignore
import { parseJsonWithNumbersAsBigint, renderJsonWithNumbersAsBigint } from "@utils/json";
// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { type ReadonlyUint8Array } from "@utils/arrayUtils";
import { type AdditionalPropertiesExample1 } from "./schema";
import { type AdditionalPropertiesExample2 } from "./schema";
import { type AdditionalPropertiesExample3 } from "./schema";
import { type AdditionalPropertiesExample4 } from "./schema";
import { type AllOfExample } from "./schema";
import { type AllOfExpandingRef } from "./schema";
import { type AllOfRefExample } from "./schema";
import { type ArrayOfArray } from "./schema";
import { type BinaryData } from "./schema";
import { type BytesData } from "./schema";
import { type DateObj } from "./schema";
import { type DateTimeObj } from "./schema";
import { type EmptyObjectExample } from "./schema";
import { type ExampleEnum } from "./schema";
import { type ExperimentModel } from "./schema";
import { type ExperimentOptionalModel } from "./schema";
import { type ExperimentRefModel } from "./schema";
import { type ExperimentalDemoModel } from "./schema";
import { type ExternalTypeExample } from "./schema";
import { type NamedEnumInArr } from "./schema";
import { type NamedEnumInObj } from "./schema";
import { type NestedBytesData } from "./schema";
import { type NonRequiredNullable } from "./schema";
import { type NullableObjectInObject } from "./schema";
import { type ObjInArrInArr } from "./schema";
import { type ObjInObjInObj } from "./schema";
import { type ObjectArrayContainer } from "./schema";
import { type OneOfEarlyName } from "./schema";
import { type OneOfExplicitDiscriminatorExample } from "./schema";
import { type OneOfExplicitDiscriminatorMultiNestingExample } from "./schema";
import { type OneOfInsideArrayExample } from "./schema";
import { type OneOfLateName } from "./schema";
import { type OneOfPartAExample } from "./schema";
import { type OneOfPartBExample } from "./schema";
import { type OneOfPartCExample } from "./schema";
import { type OneOfPartsBaseExample } from "./schema";
import { type OneOfUsePartExample } from "./schema";
import { type OneOfWithOverlappingProperties } from "./schema";
import { type OneOfWithRef } from "./schema";
import { type OneOfWithRefElem } from "./schema";
import { type OneOfWithRefElemKind } from "./schema";
import { type RefArrayContainer } from "./schema";
import { type ReservedKeywords } from "./schema";
import { type StringArrayContainer } from "./schema";
import { type SubModel } from "./schema";
import { type SuperExample } from "./schema";
import { type SuperPartAExample } from "./schema";
import { type SuperPartBExample } from "./schema";
import { type TimeStampExample } from "./schema";
import { type UnNamedEnumInArr } from "./schema";
import { type UnnamedEnumInObj } from "./schema";
import { type UriExample } from "./schema";
import { type UrlExample } from "./schema";
// AdditionalPropertiesExample1
export function serialize_AdditionalPropertiesExample1(obj: AdditionalPropertiesExample1): string {
  const serialized = ((value: { num: number; additionalProperties: Record<string, string> }): unknown => {
    const unordered_obj: Record<string, unknown> = {
      num: ((value: number): unknown => {
        return value;
      })(value["num"]),
      ...Object.fromEntries(
        Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
          return [
            propertyKey,
            ((value: string): unknown => {
              return value;
            })(propertyValue),
          ];
        }),
      ),
    };
    return Object.keys(unordered_obj)
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = unordered_obj[key];
          return obj;
        },
        {} as Record<string, unknown>,
      );
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdditionalPropertiesExample1(str: string): AdditionalPropertiesExample1 | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          num: number;
          additionalProperties: Record<string, string>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "num")) {
      return { success: false };
    }
    const parsed_num = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["num"]);
    })();
    if (!parsed_num.success) {
      return { success: false };
    }
    const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
      const parsed = Object.entries(value)
        .filter(([key, _]) => !["num"].includes(key))
        .map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
          return [
            key,
            ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value),
          ];
        });
      if (parsed.find(([_, value]) => !value.success) !== undefined) {
        return { success: false };
      }
      return {
        success: true,
        value: Object.fromEntries(
          parsed.map(([key, value]) => {
            assert(value.success);
            return [key, value.value];
          }),
        ),
      };
    })();
    if (!parsed_additionalProperties.success) {
      return { success: false };
    }
    return { success: true, value: { num: parsed_num.value, additionalProperties: parsed_additionalProperties.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdditionalPropertiesExample2
export function serialize_AdditionalPropertiesExample2(obj: AdditionalPropertiesExample2): string {
  const serialized = ((value: {
    additionalProperties: Record<
      string,
      {
        foo?: number;
      }
    >;
  }): unknown => {
    const unordered_obj: Record<string, unknown> = {
      ...Object.fromEntries(
        Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
          return [
            propertyKey,
            ((value: { foo?: number }): unknown => {
              return {
                foo: ((value: number | undefined): unknown => {
                  if (value === undefined) {
                    return undefined;
                  }
                  return value;
                })(value["foo"]),
              };
            })(propertyValue),
          ];
        }),
      ),
    };
    return Object.keys(unordered_obj)
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = unordered_obj[key];
          return obj;
        },
        {} as Record<string, unknown>,
      );
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdditionalPropertiesExample2(str: string): AdditionalPropertiesExample2 | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          additionalProperties: Record<
            string,
            {
              foo?: number;
            }
          >;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_additionalProperties = (():
      | { success: false }
      | {
          success: true;
          value: Record<
            string,
            {
              foo?: number;
            }
          >;
        } => {
      const parsed = Object.entries(value).map(
        ([key, value]): [
          string,
          (
            | { success: false }
            | {
                success: true;
                value: {
                  foo?: number;
                };
              }
          ),
        ] => {
          return [
            key,
            ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value: {
                    foo?: number;
                  };
                } => {
              if (typeof value !== "object") {
                return { success: false };
              }
              if (value === null) {
                return { success: false };
              }
              const parsed_foo = (() => {
                if (!hasProp(value, "foo")) {
                  return { success: true, value: undefined };
                }
                return ((value: unknown): { success: false } | { success: true; value: number | undefined } => {
                  if (value === undefined) {
                    return { success: true, value: undefined };
                  }
                  if (typeof value === "number") {
                    return { success: true, value: value };
                  }
                  return { success: false };
                })(value["foo"]);
              })();
              if (!parsed_foo.success) {
                return { success: false };
              }
              return { success: true, value: { foo: parsed_foo.value } };
            })(value),
          ];
        },
      );
      if (parsed.find(([_, value]) => !value.success) !== undefined) {
        return { success: false };
      }
      return {
        success: true,
        value: Object.fromEntries(
          parsed.map(([key, value]) => {
            assert(value.success);
            return [key, value.value];
          }),
        ),
      };
    })();
    if (!parsed_additionalProperties.success) {
      return { success: false };
    }
    return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdditionalPropertiesExample3
export function serialize_AdditionalPropertiesExample3(obj: AdditionalPropertiesExample3): string {
  const serialized = ((value: { additionalProperties: Record<string, number | null> }): unknown => {
    const unordered_obj: Record<string, unknown> = {
      ...Object.fromEntries(
        Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
          return [
            propertyKey,
            ((value: number | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(propertyValue),
          ];
        }),
      ),
    };
    return Object.keys(unordered_obj)
      .sort()
      .reduce(
        (obj, key) => {
          obj[key] = unordered_obj[key];
          return obj;
        },
        {} as Record<string, unknown>,
      );
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdditionalPropertiesExample3(str: string): AdditionalPropertiesExample3 | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, number | null> } } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, number | null> } => {
      const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: number | null }] => {
        return [
          key,
          ((value: unknown): { success: false } | { success: true; value: number | null } => {
            if (value === null) {
              return { success: true, value: null };
            }
            if (typeof value === "number") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value),
        ];
      });
      if (parsed.find(([_, value]) => !value.success) !== undefined) {
        return { success: false };
      }
      return {
        success: true,
        value: Object.fromEntries(
          parsed.map(([key, value]) => {
            assert(value.success);
            return [key, value.value];
          }),
        ),
      };
    })();
    if (!parsed_additionalProperties.success) {
      return { success: false };
    }
    return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdditionalPropertiesExample4
export function serialize_AdditionalPropertiesExample4(obj: AdditionalPropertiesExample4): string {
  const serialized = ((value: { subobj: { additionalProperties: Record<string, string> } }): unknown => {
    return {
      subobj: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["subobj"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdditionalPropertiesExample4(str: string): AdditionalPropertiesExample4 | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          subobj: { additionalProperties: Record<string, string> };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "subobj")) {
      return { success: false };
    }
    const parsed_subobj = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["subobj"]);
    })();
    if (!parsed_subobj.success) {
      return { success: false };
    }
    return { success: true, value: { subobj: parsed_subobj.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AllOfExample
export function serialize_AllOfExample(obj: AllOfExample): string {
  const serialized = ((value: { a: string; b: string; c: string; d?: string; e: string; f?: string }): unknown => {
    return {
      a: ((value: string): unknown => {
        return value;
      })(value["a"]),
      b: ((value: string): unknown => {
        return value;
      })(value["b"]),
      c: ((value: string): unknown => {
        return value;
      })(value["c"]),
      d: ((value: string | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["d"]),
      e: ((value: string): unknown => {
        return value;
      })(value["e"]),
      f: ((value: string | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["f"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AllOfExample(str: string): AllOfExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          a: string;
          b: string;
          c: string;
          d?: string;
          e: string;
          f?: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "a")) {
      return { success: false };
    }
    const parsed_a = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["a"]);
    })();
    if (!parsed_a.success) {
      return { success: false };
    }
    if (!hasProp(value, "b")) {
      return { success: false };
    }
    const parsed_b = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["b"]);
    })();
    if (!parsed_b.success) {
      return { success: false };
    }
    if (!hasProp(value, "c")) {
      return { success: false };
    }
    const parsed_c = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["c"]);
    })();
    if (!parsed_c.success) {
      return { success: false };
    }
    const parsed_d = (() => {
      if (!hasProp(value, "d")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["d"]);
    })();
    if (!parsed_d.success) {
      return { success: false };
    }
    if (!hasProp(value, "e")) {
      return { success: false };
    }
    const parsed_e = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["e"]);
    })();
    if (!parsed_e.success) {
      return { success: false };
    }
    const parsed_f = (() => {
      if (!hasProp(value, "f")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["f"]);
    })();
    if (!parsed_f.success) {
      return { success: false };
    }
    return { success: true, value: { a: parsed_a.value, b: parsed_b.value, c: parsed_c.value, d: parsed_d.value, e: parsed_e.value, f: parsed_f.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AllOfExpandingRef
export function serialize_AllOfExpandingRef(obj: AllOfExpandingRef): string {
  const serialized = ((value: { a: string; b: string; c: string; d?: string; e: string; f?: string; x: string; y: string }): unknown => {
    return {
      a: ((value: string): unknown => {
        return value;
      })(value["a"]),
      b: ((value: string): unknown => {
        return value;
      })(value["b"]),
      c: ((value: string): unknown => {
        return value;
      })(value["c"]),
      d: ((value: string | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["d"]),
      e: ((value: string): unknown => {
        return value;
      })(value["e"]),
      f: ((value: string | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["f"]),
      x: ((value: string): unknown => {
        return value;
      })(value["x"]),
      y: ((value: string): unknown => {
        return value;
      })(value["y"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AllOfExpandingRef(str: string): AllOfExpandingRef | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          a: string;
          b: string;
          c: string;
          d?: string;
          e: string;
          f?: string;
          x: string;
          y: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "a")) {
      return { success: false };
    }
    const parsed_a = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["a"]);
    })();
    if (!parsed_a.success) {
      return { success: false };
    }
    if (!hasProp(value, "b")) {
      return { success: false };
    }
    const parsed_b = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["b"]);
    })();
    if (!parsed_b.success) {
      return { success: false };
    }
    if (!hasProp(value, "c")) {
      return { success: false };
    }
    const parsed_c = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["c"]);
    })();
    if (!parsed_c.success) {
      return { success: false };
    }
    const parsed_d = (() => {
      if (!hasProp(value, "d")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["d"]);
    })();
    if (!parsed_d.success) {
      return { success: false };
    }
    if (!hasProp(value, "e")) {
      return { success: false };
    }
    const parsed_e = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["e"]);
    })();
    if (!parsed_e.success) {
      return { success: false };
    }
    const parsed_f = (() => {
      if (!hasProp(value, "f")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["f"]);
    })();
    if (!parsed_f.success) {
      return { success: false };
    }
    if (!hasProp(value, "x")) {
      return { success: false };
    }
    const parsed_x = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["x"]);
    })();
    if (!parsed_x.success) {
      return { success: false };
    }
    if (!hasProp(value, "y")) {
      return { success: false };
    }
    const parsed_y = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["y"]);
    })();
    if (!parsed_y.success) {
      return { success: false };
    }
    return { success: true, value: { a: parsed_a.value, b: parsed_b.value, c: parsed_c.value, d: parsed_d.value, e: parsed_e.value, f: parsed_f.value, x: parsed_x.value, y: parsed_y.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AllOfRefExample
export function serialize_AllOfRefExample(obj: AllOfRefExample): string {
  const serialized = ((value: { a: AllOfExample | null }): unknown => {
    return {
      a: ((
        value: {
          a: string;
          b: string;
          c: string;
          d?: string;
          e: string;
          f?: string;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          a: ((value: string): unknown => {
            return value;
          })(value["a"]),
          b: ((value: string): unknown => {
            return value;
          })(value["b"]),
          c: ((value: string): unknown => {
            return value;
          })(value["c"]),
          d: ((value: string | undefined): unknown => {
            if (value === undefined) {
              return undefined;
            }
            return value;
          })(value["d"]),
          e: ((value: string): unknown => {
            return value;
          })(value["e"]),
          f: ((value: string | undefined): unknown => {
            if (value === undefined) {
              return undefined;
            }
            return value;
          })(value["f"]),
        };
      })(value["a"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AllOfRefExample(str: string): AllOfRefExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          a: AllOfExample | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "a")) {
      return { success: false };
    }
    const parsed_a = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AllOfExample | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                a: string;
                b: string;
                c: string;
                d?: string;
                e: string;
                f?: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "a")) {
            return { success: false };
          }
          const parsed_a = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["a"]);
          })();
          if (!parsed_a.success) {
            return { success: false };
          }
          if (!hasProp(value, "b")) {
            return { success: false };
          }
          const parsed_b = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["b"]);
          })();
          if (!parsed_b.success) {
            return { success: false };
          }
          if (!hasProp(value, "c")) {
            return { success: false };
          }
          const parsed_c = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["c"]);
          })();
          if (!parsed_c.success) {
            return { success: false };
          }
          const parsed_d = (() => {
            if (!hasProp(value, "d")) {
              return { success: true, value: undefined };
            }
            return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
              if (value === undefined) {
                return { success: true, value: undefined };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["d"]);
          })();
          if (!parsed_d.success) {
            return { success: false };
          }
          if (!hasProp(value, "e")) {
            return { success: false };
          }
          const parsed_e = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["e"]);
          })();
          if (!parsed_e.success) {
            return { success: false };
          }
          const parsed_f = (() => {
            if (!hasProp(value, "f")) {
              return { success: true, value: undefined };
            }
            return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
              if (value === undefined) {
                return { success: true, value: undefined };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["f"]);
          })();
          if (!parsed_f.success) {
            return { success: false };
          }
          return { success: true, value: { a: parsed_a.value, b: parsed_b.value, c: parsed_c.value, d: parsed_d.value, e: parsed_e.value, f: parsed_f.value } };
        })(value);
      })(value["a"]);
    })();
    if (!parsed_a.success) {
      return { success: false };
    }
    return { success: true, value: { a: parsed_a.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ArrayOfArray
export function serialize_ArrayOfArray(obj: ArrayOfArray): string {
  const serialized = ((value: { arr_of_arr: number[][] }): unknown => {
    return {
      arr_of_arr: ((value: number[][]): unknown => {
        return value.map((value: number[]): unknown => {
          return value.map((value: number): unknown => {
            return value;
          });
        });
      })(value["arr_of_arr"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ArrayOfArray(str: string): ArrayOfArray | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr_of_arr: number[][];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr_of_arr")) {
      return { success: false };
    }
    const parsed_arr_of_arr = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[][] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number[] } => {
          if (!isArray(value)) {
            return { success: false };
          }
          const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
            if (typeof value === "number") {
              return { success: true, value: value };
            }
            return { success: false };
          });
          if (parsed.find((value) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: parsed.map((entry) => {
              assert(entry.success == true);
              return entry.value;
            }),
          };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr_of_arr"]);
    })();
    if (!parsed_arr_of_arr.success) {
      return { success: false };
    }
    return { success: true, value: { arr_of_arr: parsed_arr_of_arr.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// BinaryData
export function serialize_BinaryData(obj: BinaryData): string {
  const serialized = ((value: { file: ReadonlyUint8Array }): unknown => {
    return {
      file: ((value: ReadonlyUint8Array): unknown => {
        return btoa(String.fromCodePoint(...value));
      })(value["file"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_BinaryData(str: string): BinaryData | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          file: ReadonlyUint8Array;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "file")) {
      return { success: false };
    }
    const parsed_file = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReadonlyUint8Array } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const binary = (() => {
          try {
            const binaryString = atob(value);
            const arr = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              arr[i] = binaryString.charCodeAt(i);
            }
            return arr;
          } catch (ex) {
            return null;
          }
        })();
        if (binary === null) {
          return { success: false };
        }
        return { success: true, value: binary };
      })(value["file"]);
    })();
    if (!parsed_file.success) {
      return { success: false };
    }
    return { success: true, value: { file: parsed_file.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// BytesData
export function serialize_BytesData(obj: BytesData): string {
  const serialized = ((value: { data: ReadonlyUint8Array }): unknown => {
    return {
      data: ((value: ReadonlyUint8Array): unknown => {
        return btoa(String.fromCodePoint(...value));
      })(value["data"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_BytesData(str: string): BytesData | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          data: ReadonlyUint8Array;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "data")) {
      return { success: false };
    }
    const parsed_data = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReadonlyUint8Array } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const binary = (() => {
          try {
            const binaryString = atob(value);
            const arr = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              arr[i] = binaryString.charCodeAt(i);
            }
            return arr;
          } catch (ex) {
            return null;
          }
        })();
        if (binary === null) {
          return { success: false };
        }
        return { success: true, value: binary };
      })(value["data"]);
    })();
    if (!parsed_data.success) {
      return { success: false };
    }
    return { success: true, value: { data: parsed_data.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DateObj
export function serialize_DateObj(obj: DateObj): string {
  const serialized = ((value: { dt?: { year: number; month: number; day: number } | null }): unknown => {
    return {
      dt: ((value: { year: number; month: number; day: number } | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["dt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DateObj(str: string): DateObj | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          dt?: { year: number; month: number; day: number } | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_dt = (() => {
      if (!hasProp(value, "dt")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["dt"]);
    })();
    if (!parsed_dt.success) {
      return { success: false };
    }
    return { success: true, value: { dt: parsed_dt.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DateTimeObj
export function serialize_DateTimeObj(obj: DateTimeObj): string {
  const serialized = ((value: { dt?: DateTime<true> | null }): unknown => {
    return {
      dt: ((value: DateTime<true> | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["dt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DateTimeObj(str: string): DateTimeObj | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          dt?: DateTime<true> | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_dt = (() => {
      if (!hasProp(value, "dt")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["dt"]);
    })();
    if (!parsed_dt.success) {
      return { success: false };
    }
    return { success: true, value: { dt: parsed_dt.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmptyObjectExample
export function serialize_EmptyObjectExample(obj: EmptyObjectExample): string {
  const serialized = ((value: { schema?: any }): unknown => {
    return {
      schema: ((value: any | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["schema"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmptyObjectExample(str: string): EmptyObjectExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          schema?: any;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_schema = (() => {
      if (!hasProp(value, "schema")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: any | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        return { success: true, value: value };
      })(value["schema"]);
    })();
    if (!parsed_schema.success) {
      return { success: false };
    }
    return { success: true, value: { schema: parsed_schema.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ExampleEnum
export function serialize_ExampleEnum(obj: ExampleEnum): string {
  const serialized = ((value: "FOO" | "BAR"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ExampleEnum(str: string): ExampleEnum | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "FOO" | "BAR" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "FOO":
        return { success: true, value: "FOO" };
      case "BAR":
        return { success: true, value: "BAR" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ExperimentModel
export function serialize_ExperimentModel(obj: ExperimentModel): string {
  const serialized = ((value: {
    booleanExample: boolean;
    booleanNullableExample: boolean | null;
    doubleExample: number;
    doubleNullableExample: number | null;
    floatExample: number;
    floatNullableExample: number | null;
    intExample: number;
    intNullableExample: number | null;
    longExample: bigint;
    longNullableExample: bigint | null;
    stringExample: string;
    stringNullableExample: string | null;
  }): unknown => {
    return {
      booleanExample: ((value: boolean): unknown => {
        return value;
      })(value["booleanExample"]),
      booleanNullableExample: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["booleanNullableExample"]),
      doubleExample: ((value: number): unknown => {
        return value;
      })(value["doubleExample"]),
      doubleNullableExample: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doubleNullableExample"]),
      floatExample: ((value: number): unknown => {
        return value;
      })(value["floatExample"]),
      floatNullableExample: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["floatNullableExample"]),
      intExample: ((value: number): unknown => {
        return value;
      })(value["intExample"]),
      intNullableExample: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["intNullableExample"]),
      longExample: ((value: bigint): unknown => {
        return value;
      })(value["longExample"]),
      longNullableExample: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["longNullableExample"]),
      stringExample: ((value: string): unknown => {
        return value;
      })(value["stringExample"]),
      stringNullableExample: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["stringNullableExample"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ExperimentModel(str: string): ExperimentModel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          booleanExample: boolean;
          booleanNullableExample: boolean | null;
          doubleExample: number;
          doubleNullableExample: number | null;
          floatExample: number;
          floatNullableExample: number | null;
          intExample: number;
          intNullableExample: number | null;
          longExample: bigint;
          longNullableExample: bigint | null;
          stringExample: string;
          stringNullableExample: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "booleanExample")) {
      return { success: false };
    }
    const parsed_booleanExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["booleanExample"]);
    })();
    if (!parsed_booleanExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "booleanNullableExample")) {
      return { success: false };
    }
    const parsed_booleanNullableExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["booleanNullableExample"]);
    })();
    if (!parsed_booleanNullableExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "doubleExample")) {
      return { success: false };
    }
    const parsed_doubleExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["doubleExample"]);
    })();
    if (!parsed_doubleExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "doubleNullableExample")) {
      return { success: false };
    }
    const parsed_doubleNullableExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["doubleNullableExample"]);
    })();
    if (!parsed_doubleNullableExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "floatExample")) {
      return { success: false };
    }
    const parsed_floatExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["floatExample"]);
    })();
    if (!parsed_floatExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "floatNullableExample")) {
      return { success: false };
    }
    const parsed_floatNullableExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["floatNullableExample"]);
    })();
    if (!parsed_floatNullableExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "intExample")) {
      return { success: false };
    }
    const parsed_intExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["intExample"]);
    })();
    if (!parsed_intExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "intNullableExample")) {
      return { success: false };
    }
    const parsed_intNullableExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["intNullableExample"]);
    })();
    if (!parsed_intNullableExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "longExample")) {
      return { success: false };
    }
    const parsed_longExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["longExample"]);
    })();
    if (!parsed_longExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "longNullableExample")) {
      return { success: false };
    }
    const parsed_longNullableExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["longNullableExample"]);
    })();
    if (!parsed_longNullableExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "stringExample")) {
      return { success: false };
    }
    const parsed_stringExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["stringExample"]);
    })();
    if (!parsed_stringExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "stringNullableExample")) {
      return { success: false };
    }
    const parsed_stringNullableExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["stringNullableExample"]);
    })();
    if (!parsed_stringNullableExample.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        booleanExample: parsed_booleanExample.value,
        booleanNullableExample: parsed_booleanNullableExample.value,
        doubleExample: parsed_doubleExample.value,
        doubleNullableExample: parsed_doubleNullableExample.value,
        floatExample: parsed_floatExample.value,
        floatNullableExample: parsed_floatNullableExample.value,
        intExample: parsed_intExample.value,
        intNullableExample: parsed_intNullableExample.value,
        longExample: parsed_longExample.value,
        longNullableExample: parsed_longNullableExample.value,
        stringExample: parsed_stringExample.value,
        stringNullableExample: parsed_stringNullableExample.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ExperimentOptionalModel
export function serialize_ExperimentOptionalModel(obj: ExperimentOptionalModel): string {
  const serialized = ((value: { intExample: number; intNullableExample: number | null; intOptionalExample?: number; intOptionalNullableExample?: number | null }): unknown => {
    return {
      intExample: ((value: number): unknown => {
        return value;
      })(value["intExample"]),
      intNullableExample: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["intNullableExample"]),
      intOptionalExample: ((value: number | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["intOptionalExample"]),
      intOptionalNullableExample: ((value: number | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value;
      })(value["intOptionalNullableExample"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ExperimentOptionalModel(str: string): ExperimentOptionalModel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          intExample: number;
          intNullableExample: number | null;
          intOptionalExample?: number;
          intOptionalNullableExample?: number | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "intExample")) {
      return { success: false };
    }
    const parsed_intExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["intExample"]);
    })();
    if (!parsed_intExample.success) {
      return { success: false };
    }
    if (!hasProp(value, "intNullableExample")) {
      return { success: false };
    }
    const parsed_intNullableExample = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["intNullableExample"]);
    })();
    if (!parsed_intNullableExample.success) {
      return { success: false };
    }
    const parsed_intOptionalExample = (() => {
      if (!hasProp(value, "intOptionalExample")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: number | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["intOptionalExample"]);
    })();
    if (!parsed_intOptionalExample.success) {
      return { success: false };
    }
    const parsed_intOptionalNullableExample = (() => {
      if (!hasProp(value, "intOptionalNullableExample")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: number | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["intOptionalNullableExample"]);
    })();
    if (!parsed_intOptionalNullableExample.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { intExample: parsed_intExample.value, intNullableExample: parsed_intNullableExample.value, intOptionalExample: parsed_intOptionalExample.value, intOptionalNullableExample: parsed_intOptionalNullableExample.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ExperimentRefModel
export function serialize_ExperimentRefModel(obj: ExperimentRefModel): string {
  const serialized = ((value: {
    nullableSubModel: SubModel | null;
    subComponent: {
      intValue: number;
    };
    subModel: SubModel;
  }): unknown => {
    return {
      nullableSubModel: ((
        value: {
          intValue: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          intValue: ((value: number): unknown => {
            return value;
          })(value["intValue"]),
        };
      })(value["nullableSubModel"]),
      subComponent: ((value: { intValue: number }): unknown => {
        return {
          intValue: ((value: number): unknown => {
            return value;
          })(value["intValue"]),
        };
      })(value["subComponent"]),
      subModel: ((value: { intValue: number }): unknown => {
        return {
          intValue: ((value: number): unknown => {
            return value;
          })(value["intValue"]),
        };
      })(value["subModel"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ExperimentRefModel(str: string): ExperimentRefModel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          nullableSubModel: SubModel | null;
          subComponent: {
            intValue: number;
          };
          subModel: SubModel;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "nullableSubModel")) {
      return { success: false };
    }
    const parsed_nullableSubModel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubModel | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                intValue: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "intValue")) {
            return { success: false };
          }
          const parsed_intValue = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["intValue"]);
          })();
          if (!parsed_intValue.success) {
            return { success: false };
          }
          return { success: true, value: { intValue: parsed_intValue.value } };
        })(value);
      })(value["nullableSubModel"]);
    })();
    if (!parsed_nullableSubModel.success) {
      return { success: false };
    }
    if (!hasProp(value, "subComponent")) {
      return { success: false };
    }
    const parsed_subComponent = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              intValue: number;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "intValue")) {
          return { success: false };
        }
        const parsed_intValue = (() => {
          return ((value: unknown): { success: false } | { success: true; value: number } => {
            if (typeof value === "number") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value["intValue"]);
        })();
        if (!parsed_intValue.success) {
          return { success: false };
        }
        return { success: true, value: { intValue: parsed_intValue.value } };
      })(value["subComponent"]);
    })();
    if (!parsed_subComponent.success) {
      return { success: false };
    }
    if (!hasProp(value, "subModel")) {
      return { success: false };
    }
    const parsed_subModel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubModel } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                intValue: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "intValue")) {
            return { success: false };
          }
          const parsed_intValue = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["intValue"]);
          })();
          if (!parsed_intValue.success) {
            return { success: false };
          }
          return { success: true, value: { intValue: parsed_intValue.value } };
        })(value);
      })(value["subModel"]);
    })();
    if (!parsed_subModel.success) {
      return { success: false };
    }
    return { success: true, value: { nullableSubModel: parsed_nullableSubModel.value, subComponent: parsed_subComponent.value, subModel: parsed_subModel.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ExperimentalDemoModel
export function serialize_ExperimentalDemoModel(obj: ExperimentalDemoModel): string {
  const serialized = ((value: { text: string }): unknown => {
    return {
      text: ((value: string): unknown => {
        return value;
      })(value["text"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ExperimentalDemoModel(str: string): ExperimentalDemoModel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          text: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "text")) {
      return { success: false };
    }
    const parsed_text = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["text"]);
    })();
    if (!parsed_text.success) {
      return { success: false };
    }
    return { success: true, value: { text: parsed_text.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ExternalTypeExample
export function serialize_ExternalTypeExample(obj: ExternalTypeExample): string {
  const serialized = ((value: { typed?: ("IBAN" | "ABA" | "SWIFTBIC" | "CHEQUE") | null; untyped?: string | null }): unknown => {
    return {
      typed: ((value: ("IBAN" | "ABA" | "SWIFTBIC" | "CHEQUE") | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value;
      })(value["typed"]),
      untyped: ((value: string | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value;
      })(value["untyped"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ExternalTypeExample(str: string): ExternalTypeExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          typed?: ("IBAN" | "ABA" | "SWIFTBIC" | "CHEQUE") | null;
          untyped?: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_typed = (() => {
      if (!hasProp(value, "typed")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: ("IBAN" | "ABA" | "SWIFTBIC" | "CHEQUE") | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "IBAN":
            return { success: true, value: "IBAN" };
          case "ABA":
            return { success: true, value: "ABA" };
          case "SWIFTBIC":
            return { success: true, value: "SWIFTBIC" };
          case "CHEQUE":
            return { success: true, value: "CHEQUE" };
          default:
            return { success: false };
        }
      })(value["typed"]);
    })();
    if (!parsed_typed.success) {
      return { success: false };
    }
    const parsed_untyped = (() => {
      if (!hasProp(value, "untyped")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["untyped"]);
    })();
    if (!parsed_untyped.success) {
      return { success: false };
    }
    return { success: true, value: { typed: parsed_typed.value, untyped: parsed_untyped.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NamedEnumInArr
export function serialize_NamedEnumInArr(obj: NamedEnumInArr): string {
  const serialized = ((value: { arr?: ExampleEnum[] }): unknown => {
    return {
      arr: ((value: ExampleEnum[] | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value.map((value: "FOO" | "BAR"): unknown => {
          return value;
        });
      })(value["arr"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NamedEnumInArr(str: string): NamedEnumInArr | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr?: ExampleEnum[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_arr = (() => {
      if (!hasProp(value, "arr")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: ExampleEnum[] | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ExampleEnum } => {
          return ((value: unknown): { success: false } | { success: true; value: "FOO" | "BAR" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "FOO":
                return { success: true, value: "FOO" };
              case "BAR":
                return { success: true, value: "BAR" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr"]);
    })();
    if (!parsed_arr.success) {
      return { success: false };
    }
    return { success: true, value: { arr: parsed_arr.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NamedEnumInObj
export function serialize_NamedEnumInObj(obj: NamedEnumInObj): string {
  const serialized = ((value: { value?: ExampleEnum }): unknown => {
    return {
      value: ((value: ("FOO" | "BAR") | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["value"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NamedEnumInObj(str: string): NamedEnumInObj | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          value?: ExampleEnum;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_value = (() => {
      if (!hasProp(value, "value")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: ExampleEnum | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        return ((value: unknown): { success: false } | { success: true; value: "FOO" | "BAR" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FOO":
              return { success: true, value: "FOO" };
            case "BAR":
              return { success: true, value: "BAR" };
            default:
              return { success: false };
          }
        })(value);
      })(value["value"]);
    })();
    if (!parsed_value.success) {
      return { success: false };
    }
    return { success: true, value: { value: parsed_value.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NestedBytesData
export function serialize_NestedBytesData(obj: NestedBytesData): string {
  const serialized = ((value: { inner: BytesData }): unknown => {
    return {
      inner: ((value: { data: ReadonlyUint8Array }): unknown => {
        return {
          data: ((value: ReadonlyUint8Array): unknown => {
            return btoa(String.fromCodePoint(...value));
          })(value["data"]),
        };
      })(value["inner"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NestedBytesData(str: string): NestedBytesData | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          inner: BytesData;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "inner")) {
      return { success: false };
    }
    const parsed_inner = (() => {
      return ((value: unknown): { success: false } | { success: true; value: BytesData } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                data: ReadonlyUint8Array;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "data")) {
            return { success: false };
          }
          const parsed_data = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ReadonlyUint8Array } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const binary = (() => {
                try {
                  const binaryString = atob(value);
                  const arr = new Uint8Array(binaryString.length);
                  for (let i = 0; i < binaryString.length; i++) {
                    arr[i] = binaryString.charCodeAt(i);
                  }
                  return arr;
                } catch (ex) {
                  return null;
                }
              })();
              if (binary === null) {
                return { success: false };
              }
              return { success: true, value: binary };
            })(value["data"]);
          })();
          if (!parsed_data.success) {
            return { success: false };
          }
          return { success: true, value: { data: parsed_data.value } };
        })(value);
      })(value["inner"]);
    })();
    if (!parsed_inner.success) {
      return { success: false };
    }
    return { success: true, value: { inner: parsed_inner.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NonRequiredNullable
export function serialize_NonRequiredNullable(obj: NonRequiredNullable): string {
  const serialized = ((value: { data?: number | null }): unknown => {
    return {
      data: ((value: number | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value;
      })(value["data"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NonRequiredNullable(str: string): NonRequiredNullable | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          data?: number | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_data = (() => {
      if (!hasProp(value, "data")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: number | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["data"]);
    })();
    if (!parsed_data.success) {
      return { success: false };
    }
    return { success: true, value: { data: parsed_data.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NullableObjectInObject
export function serialize_NullableObjectInObject(obj: NullableObjectInObject): string {
  const serialized = ((value: {
    inner1: {
      inner2: string;
    } | null;
  }): unknown => {
    return {
      inner1: ((
        value: {
          inner2: string;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          inner2: ((value: string): unknown => {
            return value;
          })(value["inner2"]),
        };
      })(value["inner1"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NullableObjectInObject(str: string): NullableObjectInObject | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          inner1: {
            inner2: string;
          } | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "inner1")) {
      return { success: false };
    }
    const parsed_inner1 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              inner2: string;
            } | null;
          } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "inner2")) {
          return { success: false };
        }
        const parsed_inner2 = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["inner2"]);
        })();
        if (!parsed_inner2.success) {
          return { success: false };
        }
        return { success: true, value: { inner2: parsed_inner2.value } };
      })(value["inner1"]);
    })();
    if (!parsed_inner1.success) {
      return { success: false };
    }
    return { success: true, value: { inner1: parsed_inner1.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ObjInArrInArr
export function serialize_ObjInArrInArr(obj: ObjInArrInArr): string {
  const serialized = ((value: {
    arr1: {
      arr2: {
        obj1: {
          arr3: any[];
        };
      }[];
    }[];
  }): unknown => {
    return {
      arr1: ((
        value: {
          arr2: {
            obj1: {
              arr3: any[];
            };
          }[];
        }[],
      ): unknown => {
        return value.map(
          (value: {
            arr2: {
              obj1: {
                arr3: any[];
              };
            }[];
          }): unknown => {
            return {
              arr2: ((
                value: {
                  obj1: {
                    arr3: any[];
                  };
                }[],
              ): unknown => {
                return value.map(
                  (value: {
                    obj1: {
                      arr3: any[];
                    };
                  }): unknown => {
                    return {
                      obj1: ((value: { arr3: any[] }): unknown => {
                        return {
                          arr3: ((value: any[]): unknown => {
                            return value.map((value: any): unknown => {
                              return value;
                            });
                          })(value["arr3"]),
                        };
                      })(value["obj1"]),
                    };
                  },
                );
              })(value["arr2"]),
            };
          },
        );
      })(value["arr1"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ObjInArrInArr(str: string): ObjInArrInArr | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr1: {
            arr2: {
              obj1: {
                arr3: any[];
              };
            }[];
          }[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr1")) {
      return { success: false };
    }
    const parsed_arr1 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              arr2: {
                obj1: {
                  arr3: any[];
                };
              }[];
            }[];
          } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  arr2: {
                    obj1: {
                      arr3: any[];
                    };
                  }[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "arr2")) {
              return { success: false };
            }
            const parsed_arr2 = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      obj1: {
                        arr3: any[];
                      };
                    }[];
                  } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map(
                  (
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          obj1: {
                            arr3: any[];
                          };
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "obj1")) {
                      return { success: false };
                    }
                    const parsed_obj1 = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              arr3: any[];
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "arr3")) {
                          return { success: false };
                        }
                        const parsed_arr3 = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: any[] } => {
                            if (!isArray(value)) {
                              return { success: false };
                            }
                            const parsed = value.map((value: unknown): { success: false } | { success: true; value: any } => {
                              return { success: true, value: value };
                            });
                            if (parsed.find((value) => !value.success) !== undefined) {
                              return { success: false };
                            }
                            return {
                              success: true,
                              value: parsed.map((entry) => {
                                assert(entry.success == true);
                                return entry.value;
                              }),
                            };
                          })(value["arr3"]);
                        })();
                        if (!parsed_arr3.success) {
                          return { success: false };
                        }
                        return { success: true, value: { arr3: parsed_arr3.value } };
                      })(value["obj1"]);
                    })();
                    if (!parsed_obj1.success) {
                      return { success: false };
                    }
                    return { success: true, value: { obj1: parsed_obj1.value } };
                  },
                );
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["arr2"]);
            })();
            if (!parsed_arr2.success) {
              return { success: false };
            }
            return { success: true, value: { arr2: parsed_arr2.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr1"]);
    })();
    if (!parsed_arr1.success) {
      return { success: false };
    }
    return { success: true, value: { arr1: parsed_arr1.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ObjInObjInObj
export function serialize_ObjInObjInObj(obj: ObjInObjInObj): string {
  const serialized = ((value: {
    obj1: {
      obj2: {
        obj3: {
          str: string;
        };
      };
    };
  }): unknown => {
    return {
      obj1: ((value: {
        obj2: {
          obj3: {
            str: string;
          };
        };
      }): unknown => {
        return {
          obj2: ((value: {
            obj3: {
              str: string;
            };
          }): unknown => {
            return {
              obj3: ((value: { str: string }): unknown => {
                return {
                  str: ((value: string): unknown => {
                    return value;
                  })(value["str"]),
                };
              })(value["obj3"]),
            };
          })(value["obj2"]),
        };
      })(value["obj1"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ObjInObjInObj(str: string): ObjInObjInObj | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          obj1: {
            obj2: {
              obj3: {
                str: string;
              };
            };
          };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "obj1")) {
      return { success: false };
    }
    const parsed_obj1 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              obj2: {
                obj3: {
                  str: string;
                };
              };
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "obj2")) {
          return { success: false };
        }
        const parsed_obj2 = (() => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  obj3: {
                    str: string;
                  };
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "obj3")) {
              return { success: false };
            }
            const parsed_obj3 = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      str: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "str")) {
                  return { success: false };
                }
                const parsed_str = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["str"]);
                })();
                if (!parsed_str.success) {
                  return { success: false };
                }
                return { success: true, value: { str: parsed_str.value } };
              })(value["obj3"]);
            })();
            if (!parsed_obj3.success) {
              return { success: false };
            }
            return { success: true, value: { obj3: parsed_obj3.value } };
          })(value["obj2"]);
        })();
        if (!parsed_obj2.success) {
          return { success: false };
        }
        return { success: true, value: { obj2: parsed_obj2.value } };
      })(value["obj1"]);
    })();
    if (!parsed_obj1.success) {
      return { success: false };
    }
    return { success: true, value: { obj1: parsed_obj1.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ObjectArrayContainer
export function serialize_ObjectArrayContainer(obj: ObjectArrayContainer): string {
  const serialized = ((value: {
    arr1: {
      intValue: number;
    }[];
    arr2:
      | {
          intValue: number;
        }[]
      | null;
    arr3?: {
      intValue: number;
    }[];
    arr4?:
      | {
          intValue: number;
        }[]
      | null;
  }): unknown => {
    return {
      arr1: ((
        value: {
          intValue: number;
        }[],
      ): unknown => {
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr1"]),
      arr2: ((
        value:
          | {
              intValue: number;
            }[]
          | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr2"]),
      arr3: ((
        value:
          | {
              intValue: number;
            }[]
          | undefined,
      ): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr3"]),
      arr4: ((
        value:
          | {
              intValue: number;
            }[]
          | null
          | undefined,
      ): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr4"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ObjectArrayContainer(str: string): ObjectArrayContainer | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr1: {
            intValue: number;
          }[];
          arr2:
            | {
                intValue: number;
              }[]
            | null;
          arr3?: {
            intValue: number;
          }[];
          arr4?:
            | {
                intValue: number;
              }[]
            | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr1")) {
      return { success: false };
    }
    const parsed_arr1 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              intValue: number;
            }[];
          } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr1"]);
    })();
    if (!parsed_arr1.success) {
      return { success: false };
    }
    if (!hasProp(value, "arr2")) {
      return { success: false };
    }
    const parsed_arr2 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value:
              | {
                  intValue: number;
                }[]
              | null;
          } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr2"]);
    })();
    if (!parsed_arr2.success) {
      return { success: false };
    }
    const parsed_arr3 = (() => {
      if (!hasProp(value, "arr3")) {
        return { success: true, value: undefined };
      }
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value:
              | {
                  intValue: number;
                }[]
              | undefined;
          } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr3"]);
    })();
    if (!parsed_arr3.success) {
      return { success: false };
    }
    const parsed_arr4 = (() => {
      if (!hasProp(value, "arr4")) {
        return { success: true, value: undefined };
      }
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value:
              | {
                  intValue: number;
                }[]
              | null
              | undefined;
          } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr4"]);
    })();
    if (!parsed_arr4.success) {
      return { success: false };
    }
    return { success: true, value: { arr1: parsed_arr1.value, arr2: parsed_arr2.value, arr3: parsed_arr3.value, arr4: parsed_arr4.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfEarlyName
export function serialize_OneOfEarlyName(obj: OneOfEarlyName): string {
  const serialized = ((value: { a_kind: "num"; elem: string }): unknown => {
    switch (value.a_kind) {
      case "num": {
        return {
          a_kind: "num",
          elem: ((value: string): unknown => {
            return value;
          })(value["elem"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfEarlyName(str: string): OneOfEarlyName | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { a_kind: "num"; elem: string } } => {
    return ((value: unknown): { success: false } | { success: true; value: { a_kind: "num"; elem: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "a_kind")) {
        return { success: false };
      }
      if (typeof value["a_kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["a_kind"];
      switch (discriminator) {
        case "num": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  elem: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "elem")) {
              return { success: false };
            }
            const parsed_elem = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["elem"]);
            })();
            if (!parsed_elem.success) {
              return { success: false };
            }
            return { success: true, value: { elem: parsed_elem.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { a_kind: "num" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfExplicitDiscriminatorExample
export function serialize_OneOfExplicitDiscriminatorExample(obj: OneOfExplicitDiscriminatorExample): string {
  const serialized = ((value: { kind: "num"; num?: number } | { kind: "string"; str?: string }): unknown => {
    switch (value.kind) {
      case "num": {
        return {
          kind: "num",
          num: ((value: number | undefined): unknown => {
            if (value === undefined) {
              return undefined;
            }
            return value;
          })(value["num"]),
        };
      }
      case "string": {
        return {
          kind: "string",
          str: ((value: string | undefined): unknown => {
            if (value === undefined) {
              return undefined;
            }
            return value;
          })(value["str"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfExplicitDiscriminatorExample(str: string): OneOfExplicitDiscriminatorExample | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "num"; num?: number } | { kind: "string"; str?: string } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "num"; num?: number } | { kind: "string"; str?: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "num": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  num?: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            const parsed_num = (() => {
              if (!hasProp(value, "num")) {
                return { success: true, value: undefined };
              }
              return ((value: unknown): { success: false } | { success: true; value: number | undefined } => {
                if (value === undefined) {
                  return { success: true, value: undefined };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["num"]);
            })();
            if (!parsed_num.success) {
              return { success: false };
            }
            return { success: true, value: { num: parsed_num.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "num" as const, ...parsedVariant.value } };
        }
        case "string": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  str?: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            const parsed_str = (() => {
              if (!hasProp(value, "str")) {
                return { success: true, value: undefined };
              }
              return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
                if (value === undefined) {
                  return { success: true, value: undefined };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["str"]);
            })();
            if (!parsed_str.success) {
              return { success: false };
            }
            return { success: true, value: { str: parsed_str.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "string" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfExplicitDiscriminatorMultiNestingExample
export function serialize_OneOfExplicitDiscriminatorMultiNestingExample(obj: OneOfExplicitDiscriminatorMultiNestingExample): string {
  const serialized = ((
    value:
      | {
          kind: "obj";
          obj_data: {
            inner: { kind: "a"; a_data: number } | { kind: "b"; b_data: string };
          };
        }
      | { kind: "string"; str_data: string },
  ): unknown => {
    switch (value.kind) {
      case "obj": {
        return {
          kind: "obj",
          obj_data: ((value: { inner: { kind: "a"; a_data: number } | { kind: "b"; b_data: string } }): unknown => {
            return {
              inner: ((value: { kind: "a"; a_data: number } | { kind: "b"; b_data: string }): unknown => {
                switch (value.kind) {
                  case "a": {
                    return {
                      kind: "a",
                      a_data: ((value: number): unknown => {
                        return value;
                      })(value["a_data"]),
                    };
                  }
                  case "b": {
                    return {
                      kind: "b",
                      b_data: ((value: string): unknown => {
                        return value;
                      })(value["b_data"]),
                    };
                  }
                }
              })(value["inner"]),
            };
          })(value["obj_data"]),
        };
      }
      case "string": {
        return {
          kind: "string",
          str_data: ((value: string): unknown => {
            return value;
          })(value["str_data"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfExplicitDiscriminatorMultiNestingExample(str: string): OneOfExplicitDiscriminatorMultiNestingExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | {
              kind: "obj";
              obj_data: {
                inner: { kind: "a"; a_data: number } | { kind: "b"; b_data: string };
              };
            }
          | { kind: "string"; str_data: string };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | {
                kind: "obj";
                obj_data: {
                  inner: { kind: "a"; a_data: number } | { kind: "b"; b_data: string };
                };
              }
            | { kind: "string"; str_data: string };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "obj": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  obj_data: {
                    inner: { kind: "a"; a_data: number } | { kind: "b"; b_data: string };
                  };
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "obj_data")) {
              return { success: false };
            }
            const parsed_obj_data = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      inner: { kind: "a"; a_data: number } | { kind: "b"; b_data: string };
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "inner")) {
                  return { success: false };
                }
                const parsed_inner = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { kind: "a"; a_data: number } | { kind: "b"; b_data: string } } => {
                    return ((value: unknown): { success: false } | { success: true; value: { kind: "a"; a_data: number } | { kind: "b"; b_data: string } } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "kind")) {
                        return { success: false };
                      }
                      if (typeof value["kind"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["kind"];
                      switch (discriminator) {
                        case "a": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  a_data: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "a_data")) {
                              return { success: false };
                            }
                            const parsed_a_data = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["a_data"]);
                            })();
                            if (!parsed_a_data.success) {
                              return { success: false };
                            }
                            return { success: true, value: { a_data: parsed_a_data.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { kind: "a" as const, ...parsedVariant.value } };
                        }
                        case "b": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  b_data: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "b_data")) {
                              return { success: false };
                            }
                            const parsed_b_data = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["b_data"]);
                            })();
                            if (!parsed_b_data.success) {
                              return { success: false };
                            }
                            return { success: true, value: { b_data: parsed_b_data.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { kind: "b" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value["inner"]);
                })();
                if (!parsed_inner.success) {
                  return { success: false };
                }
                return { success: true, value: { inner: parsed_inner.value } };
              })(value["obj_data"]);
            })();
            if (!parsed_obj_data.success) {
              return { success: false };
            }
            return { success: true, value: { obj_data: parsed_obj_data.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "obj" as const, ...parsedVariant.value } };
        }
        case "string": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  str_data: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "str_data")) {
              return { success: false };
            }
            const parsed_str_data = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["str_data"]);
            })();
            if (!parsed_str_data.success) {
              return { success: false };
            }
            return { success: true, value: { str_data: parsed_str_data.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "string" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfInsideArrayExample
export function serialize_OneOfInsideArrayExample(obj: OneOfInsideArrayExample): string {
  const serialized = ((value: { arr: ({ kind: "one"; one_data: number } | { kind: "two"; two_data: string })[] }): unknown => {
    return {
      arr: ((value: ({ kind: "one"; one_data: number } | { kind: "two"; two_data: string })[]): unknown => {
        return value.map((value: { kind: "one"; one_data: number } | { kind: "two"; two_data: string }): unknown => {
          switch (value.kind) {
            case "one": {
              return {
                kind: "one",
                one_data: ((value: number): unknown => {
                  return value;
                })(value["one_data"]),
              };
            }
            case "two": {
              return {
                kind: "two",
                two_data: ((value: string): unknown => {
                  return value;
                })(value["two_data"]),
              };
            }
          }
        });
      })(value["arr"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfInsideArrayExample(str: string): OneOfInsideArrayExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr: ({ kind: "one"; one_data: number } | { kind: "two"; two_data: string })[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr")) {
      return { success: false };
    }
    const parsed_arr = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ({ kind: "one"; one_data: number } | { kind: "two"; two_data: string })[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: { kind: "one"; one_data: number } | { kind: "two"; two_data: string } } => {
          return ((value: unknown): { success: false } | { success: true; value: { kind: "one"; one_data: number } | { kind: "two"; two_data: string } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            if (typeof value["kind"] != "string") {
              return { success: false };
            }
            const discriminator = value["kind"];
            switch (discriminator) {
              case "one": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        one_data: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "one_data")) {
                    return { success: false };
                  }
                  const parsed_one_data = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["one_data"]);
                  })();
                  if (!parsed_one_data.success) {
                    return { success: false };
                  }
                  return { success: true, value: { one_data: parsed_one_data.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "one" as const, ...parsedVariant.value } };
              }
              case "two": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        two_data: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "two_data")) {
                    return { success: false };
                  }
                  const parsed_two_data = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["two_data"]);
                  })();
                  if (!parsed_two_data.success) {
                    return { success: false };
                  }
                  return { success: true, value: { two_data: parsed_two_data.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "two" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr"]);
    })();
    if (!parsed_arr.success) {
      return { success: false };
    }
    return { success: true, value: { arr: parsed_arr.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfLateName
export function serialize_OneOfLateName(obj: OneOfLateName): string {
  const serialized = ((value: { z_kind: "num"; elem: string }): unknown => {
    switch (value.z_kind) {
      case "num": {
        return {
          z_kind: "num",
          elem: ((value: string): unknown => {
            return value;
          })(value["elem"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfLateName(str: string): OneOfLateName | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { z_kind: "num"; elem: string } } => {
    return ((value: unknown): { success: false } | { success: true; value: { z_kind: "num"; elem: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "z_kind")) {
        return { success: false };
      }
      if (typeof value["z_kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["z_kind"];
      switch (discriminator) {
        case "num": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  elem: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "elem")) {
              return { success: false };
            }
            const parsed_elem = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["elem"]);
            })();
            if (!parsed_elem.success) {
              return { success: false };
            }
            return { success: true, value: { elem: parsed_elem.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { z_kind: "num" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfPartAExample
export function serialize_OneOfPartAExample(obj: OneOfPartAExample): string {
  const serialized = ((value: { kind: "A"; maybeString: string; sharedProp: string; subNonNullable: SuperPartAExample; subNullable: SuperPartAExample }): unknown => {
    return {
      kind: ((value: "A"): unknown => {
        return value;
      })(value["kind"]),
      maybeString: ((value: string): unknown => {
        return value;
      })(value["maybeString"]),
      sharedProp: ((value: string): unknown => {
        return value;
      })(value["sharedProp"]),
      subNonNullable: ((value: { kind: "AAA"; value: string }): unknown => {
        return {
          kind: ((value: "AAA"): unknown => {
            return value;
          })(value["kind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      })(value["subNonNullable"]),
      subNullable: ((value: { kind: "AAA"; value: string }): unknown => {
        return {
          kind: ((value: "AAA"): unknown => {
            return value;
          })(value["kind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      })(value["subNullable"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfPartAExample(str: string): OneOfPartAExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          kind: "A";
          maybeString: string;
          sharedProp: string;
          subNonNullable: SuperPartAExample;
          subNullable: SuperPartAExample;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "A" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "A":
            return { success: true, value: "A" };
          default:
            return { success: false };
        }
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    if (!hasProp(value, "maybeString")) {
      return { success: false };
    }
    const parsed_maybeString = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["maybeString"]);
    })();
    if (!parsed_maybeString.success) {
      return { success: false };
    }
    if (!hasProp(value, "sharedProp")) {
      return { success: false };
    }
    const parsed_sharedProp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sharedProp"]);
    })();
    if (!parsed_sharedProp.success) {
      return { success: false };
    }
    if (!hasProp(value, "subNonNullable")) {
      return { success: false };
    }
    const parsed_subNonNullable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SuperPartAExample } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                kind: "AAA";
                value: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "kind")) {
            return { success: false };
          }
          const parsed_kind = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "AAA" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "AAA":
                  return { success: true, value: "AAA" };
                default:
                  return { success: false };
              }
            })(value["kind"]);
          })();
          if (!parsed_kind.success) {
            return { success: false };
          }
          if (!hasProp(value, "value")) {
            return { success: false };
          }
          const parsed_value = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["value"]);
          })();
          if (!parsed_value.success) {
            return { success: false };
          }
          return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
        })(value);
      })(value["subNonNullable"]);
    })();
    if (!parsed_subNonNullable.success) {
      return { success: false };
    }
    if (!hasProp(value, "subNullable")) {
      return { success: false };
    }
    const parsed_subNullable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SuperPartAExample } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                kind: "AAA";
                value: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "kind")) {
            return { success: false };
          }
          const parsed_kind = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "AAA" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "AAA":
                  return { success: true, value: "AAA" };
                default:
                  return { success: false };
              }
            })(value["kind"]);
          })();
          if (!parsed_kind.success) {
            return { success: false };
          }
          if (!hasProp(value, "value")) {
            return { success: false };
          }
          const parsed_value = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["value"]);
          })();
          if (!parsed_value.success) {
            return { success: false };
          }
          return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
        })(value);
      })(value["subNullable"]);
    })();
    if (!parsed_subNullable.success) {
      return { success: false };
    }
    return { success: true, value: { kind: parsed_kind.value, maybeString: parsed_maybeString.value, sharedProp: parsed_sharedProp.value, subNonNullable: parsed_subNonNullable.value, subNullable: parsed_subNullable.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfPartBExample
export function serialize_OneOfPartBExample(obj: OneOfPartBExample): string {
  const serialized = ((value: { kind: "B"; sharedProp: string; subNonNullable: SuperPartBExample; subNullable: SuperPartBExample }): unknown => {
    return {
      kind: ((value: "B"): unknown => {
        return value;
      })(value["kind"]),
      sharedProp: ((value: string): unknown => {
        return value;
      })(value["sharedProp"]),
      subNonNullable: ((value: { kind: "BBB"; value: boolean }): unknown => {
        return {
          kind: ((value: "BBB"): unknown => {
            return value;
          })(value["kind"]),
          value: ((value: boolean): unknown => {
            return value;
          })(value["value"]),
        };
      })(value["subNonNullable"]),
      subNullable: ((value: { kind: "BBB"; value: boolean }): unknown => {
        return {
          kind: ((value: "BBB"): unknown => {
            return value;
          })(value["kind"]),
          value: ((value: boolean): unknown => {
            return value;
          })(value["value"]),
        };
      })(value["subNullable"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfPartBExample(str: string): OneOfPartBExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          kind: "B";
          sharedProp: string;
          subNonNullable: SuperPartBExample;
          subNullable: SuperPartBExample;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "B" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "B":
            return { success: true, value: "B" };
          default:
            return { success: false };
        }
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    if (!hasProp(value, "sharedProp")) {
      return { success: false };
    }
    const parsed_sharedProp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sharedProp"]);
    })();
    if (!parsed_sharedProp.success) {
      return { success: false };
    }
    if (!hasProp(value, "subNonNullable")) {
      return { success: false };
    }
    const parsed_subNonNullable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SuperPartBExample } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                kind: "BBB";
                value: boolean;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "kind")) {
            return { success: false };
          }
          const parsed_kind = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "BBB" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "BBB":
                  return { success: true, value: "BBB" };
                default:
                  return { success: false };
              }
            })(value["kind"]);
          })();
          if (!parsed_kind.success) {
            return { success: false };
          }
          if (!hasProp(value, "value")) {
            return { success: false };
          }
          const parsed_value = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["value"]);
          })();
          if (!parsed_value.success) {
            return { success: false };
          }
          return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
        })(value);
      })(value["subNonNullable"]);
    })();
    if (!parsed_subNonNullable.success) {
      return { success: false };
    }
    if (!hasProp(value, "subNullable")) {
      return { success: false };
    }
    const parsed_subNullable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SuperPartBExample } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                kind: "BBB";
                value: boolean;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "kind")) {
            return { success: false };
          }
          const parsed_kind = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "BBB" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "BBB":
                  return { success: true, value: "BBB" };
                default:
                  return { success: false };
              }
            })(value["kind"]);
          })();
          if (!parsed_kind.success) {
            return { success: false };
          }
          if (!hasProp(value, "value")) {
            return { success: false };
          }
          const parsed_value = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["value"]);
          })();
          if (!parsed_value.success) {
            return { success: false };
          }
          return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
        })(value);
      })(value["subNullable"]);
    })();
    if (!parsed_subNullable.success) {
      return { success: false };
    }
    return { success: true, value: { kind: parsed_kind.value, sharedProp: parsed_sharedProp.value, subNonNullable: parsed_subNonNullable.value, subNullable: parsed_subNullable.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfPartCExample
export function serialize_OneOfPartCExample(obj: OneOfPartCExample): string {
  const serialized = ((value: { kind: "C"; maybeString: string; sharedProp: string; subNonNullable: SuperExample }): unknown => {
    return {
      kind: ((value: "C"): unknown => {
        return value;
      })(value["kind"]),
      maybeString: ((value: string): unknown => {
        return value;
      })(value["maybeString"]),
      sharedProp: ((value: string): unknown => {
        return value;
      })(value["sharedProp"]),
      subNonNullable: ((value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean }): unknown => {
        switch (value.kind) {
          case "AAA": {
            return {
              kind: "AAA",
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "BBB": {
            return {
              kind: "BBB",
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          }
        }
      })(value["subNonNullable"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfPartCExample(str: string): OneOfPartCExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          kind: "C";
          maybeString: string;
          sharedProp: string;
          subNonNullable: SuperExample;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "C" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "C":
            return { success: true, value: "C" };
          default:
            return { success: false };
        }
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    if (!hasProp(value, "maybeString")) {
      return { success: false };
    }
    const parsed_maybeString = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["maybeString"]);
    })();
    if (!parsed_maybeString.success) {
      return { success: false };
    }
    if (!hasProp(value, "sharedProp")) {
      return { success: false };
    }
    const parsed_sharedProp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sharedProp"]);
    })();
    if (!parsed_sharedProp.success) {
      return { success: false };
    }
    if (!hasProp(value, "subNonNullable")) {
      return { success: false };
    }
    const parsed_subNonNullable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SuperExample } => {
        return ((value: unknown): { success: false } | { success: true; value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean } } => {
          return ((value: unknown): { success: false } | { success: true; value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            if (typeof value["kind"] != "string") {
              return { success: false };
            }
            const discriminator = value["kind"];
            switch (discriminator) {
              case "AAA": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "AAA" as const, ...parsedVariant.value } };
              }
              case "BBB": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "BBB" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["subNonNullable"]);
    })();
    if (!parsed_subNonNullable.success) {
      return { success: false };
    }
    return { success: true, value: { kind: parsed_kind.value, maybeString: parsed_maybeString.value, sharedProp: parsed_sharedProp.value, subNonNullable: parsed_subNonNullable.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfPartsBaseExample
export function serialize_OneOfPartsBaseExample(obj: OneOfPartsBaseExample): string {
  const serialized = ((
    value:
      | { kind: "A"; maybeString: string; sharedProp: string; subNonNullable: SuperPartAExample; subNullable: SuperPartAExample }
      | { kind: "B"; sharedProp: string; subNonNullable: SuperPartBExample; subNullable: SuperPartBExample }
      | { kind: "C"; maybeString: string; sharedProp: string; subNonNullable: SuperExample },
  ): unknown => {
    switch (value.kind) {
      case "A": {
        return {
          kind: "A",
          maybeString: ((value: string): unknown => {
            return value;
          })(value["maybeString"]),
          sharedProp: ((value: string): unknown => {
            return value;
          })(value["sharedProp"]),
          subNonNullable: ((value: { kind: "AAA"; value: string }): unknown => {
            return {
              kind: ((value: "AAA"): unknown => {
                return value;
              })(value["kind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          })(value["subNonNullable"]),
          subNullable: ((value: { kind: "AAA"; value: string }): unknown => {
            return {
              kind: ((value: "AAA"): unknown => {
                return value;
              })(value["kind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          })(value["subNullable"]),
        };
      }
      case "B": {
        return {
          kind: "B",
          sharedProp: ((value: string): unknown => {
            return value;
          })(value["sharedProp"]),
          subNonNullable: ((value: { kind: "BBB"; value: boolean }): unknown => {
            return {
              kind: ((value: "BBB"): unknown => {
                return value;
              })(value["kind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          })(value["subNonNullable"]),
          subNullable: ((value: { kind: "BBB"; value: boolean }): unknown => {
            return {
              kind: ((value: "BBB"): unknown => {
                return value;
              })(value["kind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          })(value["subNullable"]),
        };
      }
      case "C": {
        return {
          kind: "C",
          maybeString: ((value: string): unknown => {
            return value;
          })(value["maybeString"]),
          sharedProp: ((value: string): unknown => {
            return value;
          })(value["sharedProp"]),
          subNonNullable: ((value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean }): unknown => {
            switch (value.kind) {
              case "AAA": {
                return {
                  kind: "AAA",
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "BBB": {
                return {
                  kind: "BBB",
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          })(value["subNonNullable"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfPartsBaseExample(str: string): OneOfPartsBaseExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { kind: "A"; maybeString: string; sharedProp: string; subNonNullable: SuperPartAExample; subNullable: SuperPartAExample }
          | { kind: "B"; sharedProp: string; subNonNullable: SuperPartBExample; subNullable: SuperPartBExample }
          | { kind: "C"; maybeString: string; sharedProp: string; subNonNullable: SuperExample };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { kind: "A"; maybeString: string; sharedProp: string; subNonNullable: SuperPartAExample; subNullable: SuperPartAExample }
            | { kind: "B"; sharedProp: string; subNonNullable: SuperPartBExample; subNullable: SuperPartBExample }
            | { kind: "C"; maybeString: string; sharedProp: string; subNonNullable: SuperExample };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "A": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  maybeString: string;
                  sharedProp: string;
                  subNonNullable: SuperPartAExample;
                  subNullable: SuperPartAExample;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "maybeString")) {
              return { success: false };
            }
            const parsed_maybeString = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["maybeString"]);
            })();
            if (!parsed_maybeString.success) {
              return { success: false };
            }
            if (!hasProp(value, "sharedProp")) {
              return { success: false };
            }
            const parsed_sharedProp = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["sharedProp"]);
            })();
            if (!parsed_sharedProp.success) {
              return { success: false };
            }
            if (!hasProp(value, "subNonNullable")) {
              return { success: false };
            }
            const parsed_subNonNullable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SuperPartAExample } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        kind: "AAA";
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "AAA" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "AAA":
                          return { success: true, value: "AAA" };
                        default:
                          return { success: false };
                      }
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
                })(value);
              })(value["subNonNullable"]);
            })();
            if (!parsed_subNonNullable.success) {
              return { success: false };
            }
            if (!hasProp(value, "subNullable")) {
              return { success: false };
            }
            const parsed_subNullable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SuperPartAExample } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        kind: "AAA";
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "AAA" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "AAA":
                          return { success: true, value: "AAA" };
                        default:
                          return { success: false };
                      }
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
                })(value);
              })(value["subNullable"]);
            })();
            if (!parsed_subNullable.success) {
              return { success: false };
            }
            return { success: true, value: { maybeString: parsed_maybeString.value, sharedProp: parsed_sharedProp.value, subNonNullable: parsed_subNonNullable.value, subNullable: parsed_subNullable.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "A" as const, ...parsedVariant.value } };
        }
        case "B": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  sharedProp: string;
                  subNonNullable: SuperPartBExample;
                  subNullable: SuperPartBExample;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "sharedProp")) {
              return { success: false };
            }
            const parsed_sharedProp = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["sharedProp"]);
            })();
            if (!parsed_sharedProp.success) {
              return { success: false };
            }
            if (!hasProp(value, "subNonNullable")) {
              return { success: false };
            }
            const parsed_subNonNullable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SuperPartBExample } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        kind: "BBB";
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "BBB" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "BBB":
                          return { success: true, value: "BBB" };
                        default:
                          return { success: false };
                      }
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
                })(value);
              })(value["subNonNullable"]);
            })();
            if (!parsed_subNonNullable.success) {
              return { success: false };
            }
            if (!hasProp(value, "subNullable")) {
              return { success: false };
            }
            const parsed_subNullable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SuperPartBExample } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        kind: "BBB";
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "BBB" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "BBB":
                          return { success: true, value: "BBB" };
                        default:
                          return { success: false };
                      }
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
                })(value);
              })(value["subNullable"]);
            })();
            if (!parsed_subNullable.success) {
              return { success: false };
            }
            return { success: true, value: { sharedProp: parsed_sharedProp.value, subNonNullable: parsed_subNonNullable.value, subNullable: parsed_subNullable.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "B" as const, ...parsedVariant.value } };
        }
        case "C": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  maybeString: string;
                  sharedProp: string;
                  subNonNullable: SuperExample;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "maybeString")) {
              return { success: false };
            }
            const parsed_maybeString = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["maybeString"]);
            })();
            if (!parsed_maybeString.success) {
              return { success: false };
            }
            if (!hasProp(value, "sharedProp")) {
              return { success: false };
            }
            const parsed_sharedProp = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["sharedProp"]);
            })();
            if (!parsed_sharedProp.success) {
              return { success: false };
            }
            if (!hasProp(value, "subNonNullable")) {
              return { success: false };
            }
            const parsed_subNonNullable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SuperExample } => {
                return ((value: unknown): { success: false } | { success: true; value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean } } => {
                  return ((value: unknown): { success: false } | { success: true; value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean } } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    if (typeof value["kind"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["kind"];
                    switch (discriminator) {
                      case "AAA": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { kind: "AAA" as const, ...parsedVariant.value } };
                      }
                      case "BBB": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { kind: "BBB" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              })(value["subNonNullable"]);
            })();
            if (!parsed_subNonNullable.success) {
              return { success: false };
            }
            return { success: true, value: { maybeString: parsed_maybeString.value, sharedProp: parsed_sharedProp.value, subNonNullable: parsed_subNonNullable.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "C" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfUsePartExample
export function serialize_OneOfUsePartExample(obj: OneOfUsePartExample): string {
  const serialized = ((value: { bbb: OneOfPartBExample }): unknown => {
    return {
      bbb: ((value: { kind: "B"; sharedProp: string; subNonNullable: SuperPartBExample; subNullable: SuperPartBExample }): unknown => {
        return {
          kind: ((value: "B"): unknown => {
            return value;
          })(value["kind"]),
          sharedProp: ((value: string): unknown => {
            return value;
          })(value["sharedProp"]),
          subNonNullable: ((value: { kind: "BBB"; value: boolean }): unknown => {
            return {
              kind: ((value: "BBB"): unknown => {
                return value;
              })(value["kind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          })(value["subNonNullable"]),
          subNullable: ((value: { kind: "BBB"; value: boolean }): unknown => {
            return {
              kind: ((value: "BBB"): unknown => {
                return value;
              })(value["kind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          })(value["subNullable"]),
        };
      })(value["bbb"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfUsePartExample(str: string): OneOfUsePartExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bbb: OneOfPartBExample;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bbb")) {
      return { success: false };
    }
    const parsed_bbb = (() => {
      return ((value: unknown): { success: false } | { success: true; value: OneOfPartBExample } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                kind: "B";
                sharedProp: string;
                subNonNullable: SuperPartBExample;
                subNullable: SuperPartBExample;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "kind")) {
            return { success: false };
          }
          const parsed_kind = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "B" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "B":
                  return { success: true, value: "B" };
                default:
                  return { success: false };
              }
            })(value["kind"]);
          })();
          if (!parsed_kind.success) {
            return { success: false };
          }
          if (!hasProp(value, "sharedProp")) {
            return { success: false };
          }
          const parsed_sharedProp = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["sharedProp"]);
          })();
          if (!parsed_sharedProp.success) {
            return { success: false };
          }
          if (!hasProp(value, "subNonNullable")) {
            return { success: false };
          }
          const parsed_subNonNullable = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SuperPartBExample } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      kind: "BBB";
                      value: boolean;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "kind")) {
                  return { success: false };
                }
                const parsed_kind = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: "BBB" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "BBB":
                        return { success: true, value: "BBB" };
                      default:
                        return { success: false };
                    }
                  })(value["kind"]);
                })();
                if (!parsed_kind.success) {
                  return { success: false };
                }
                if (!hasProp(value, "value")) {
                  return { success: false };
                }
                const parsed_value = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["value"]);
                })();
                if (!parsed_value.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
              })(value);
            })(value["subNonNullable"]);
          })();
          if (!parsed_subNonNullable.success) {
            return { success: false };
          }
          if (!hasProp(value, "subNullable")) {
            return { success: false };
          }
          const parsed_subNullable = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SuperPartBExample } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      kind: "BBB";
                      value: boolean;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "kind")) {
                  return { success: false };
                }
                const parsed_kind = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: "BBB" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "BBB":
                        return { success: true, value: "BBB" };
                      default:
                        return { success: false };
                    }
                  })(value["kind"]);
                })();
                if (!parsed_kind.success) {
                  return { success: false };
                }
                if (!hasProp(value, "value")) {
                  return { success: false };
                }
                const parsed_value = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["value"]);
                })();
                if (!parsed_value.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
              })(value);
            })(value["subNullable"]);
          })();
          if (!parsed_subNullable.success) {
            return { success: false };
          }
          return { success: true, value: { kind: parsed_kind.value, sharedProp: parsed_sharedProp.value, subNonNullable: parsed_subNonNullable.value, subNullable: parsed_subNullable.value } };
        })(value);
      })(value["bbb"]);
    })();
    if (!parsed_bbb.success) {
      return { success: false };
    }
    return { success: true, value: { bbb: parsed_bbb.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfWithOverlappingProperties
export function serialize_OneOfWithOverlappingProperties(obj: OneOfWithOverlappingProperties): string {
  const serialized = ((value: { kind: "str"; value: string } | { kind: "num"; value: number }): unknown => {
    switch (value.kind) {
      case "str": {
        return {
          kind: "str",
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "num": {
        return {
          kind: "num",
          value: ((value: number): unknown => {
            return value;
          })(value["value"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfWithOverlappingProperties(str: string): OneOfWithOverlappingProperties | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "str"; value: string } | { kind: "num"; value: number } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "str"; value: string } | { kind: "num"; value: number } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "str": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "str" as const, ...parsedVariant.value } };
        }
        case "num": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  value: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "num" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfWithRef
export function serialize_OneOfWithRef(obj: OneOfWithRef): string {
  const serialized = ((value: { kind: "elem"; value: string }): unknown => {
    switch (value.kind) {
      case "elem": {
        return {
          kind: "elem",
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfWithRef(str: string): OneOfWithRef | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "elem"; value: string } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "elem"; value: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "elem": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "elem" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfWithRefElem
export function serialize_OneOfWithRefElem(obj: OneOfWithRefElem): string {
  const serialized = ((value: { kind: OneOfWithRefElemKind; value: string }): unknown => {
    return {
      kind: ((value: "elem"): unknown => {
        return value;
      })(value["kind"]),
      value: ((value: string): unknown => {
        return value;
      })(value["value"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfWithRefElem(str: string): OneOfWithRefElem | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          kind: OneOfWithRefElemKind;
          value: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: OneOfWithRefElemKind } => {
        return ((value: unknown): { success: false } | { success: true; value: "elem" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "elem":
              return { success: true, value: "elem" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    if (!hasProp(value, "value")) {
      return { success: false };
    }
    const parsed_value = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["value"]);
    })();
    if (!parsed_value.success) {
      return { success: false };
    }
    return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OneOfWithRefElemKind
export function serialize_OneOfWithRefElemKind(obj: OneOfWithRefElemKind): string {
  const serialized = ((value: "elem"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OneOfWithRefElemKind(str: string): OneOfWithRefElemKind | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "elem" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "elem":
        return { success: true, value: "elem" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RefArrayContainer
export function serialize_RefArrayContainer(obj: RefArrayContainer): string {
  const serialized = ((value: { arr1: SubModel[]; arr2: SubModel[] | null; arr3?: SubModel[]; arr4?: SubModel[] | null }): unknown => {
    return {
      arr1: ((value: SubModel[]): unknown => {
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr1"]),
      arr2: ((value: SubModel[] | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr2"]),
      arr3: ((value: SubModel[] | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr3"]),
      arr4: ((value: SubModel[] | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value.map((value: { intValue: number }): unknown => {
          return {
            intValue: ((value: number): unknown => {
              return value;
            })(value["intValue"]),
          };
        });
      })(value["arr4"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RefArrayContainer(str: string): RefArrayContainer | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr1: SubModel[];
          arr2: SubModel[] | null;
          arr3?: SubModel[];
          arr4?: SubModel[] | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr1")) {
      return { success: false };
    }
    const parsed_arr1 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubModel[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SubModel } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr1"]);
    })();
    if (!parsed_arr1.success) {
      return { success: false };
    }
    if (!hasProp(value, "arr2")) {
      return { success: false };
    }
    const parsed_arr2 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubModel[] | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SubModel } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr2"]);
    })();
    if (!parsed_arr2.success) {
      return { success: false };
    }
    const parsed_arr3 = (() => {
      if (!hasProp(value, "arr3")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: SubModel[] | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SubModel } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr3"]);
    })();
    if (!parsed_arr3.success) {
      return { success: false };
    }
    const parsed_arr4 = (() => {
      if (!hasProp(value, "arr4")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: SubModel[] | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SubModel } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  intValue: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "intValue")) {
              return { success: false };
            }
            const parsed_intValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["intValue"]);
            })();
            if (!parsed_intValue.success) {
              return { success: false };
            }
            return { success: true, value: { intValue: parsed_intValue.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr4"]);
    })();
    if (!parsed_arr4.success) {
      return { success: false };
    }
    return { success: true, value: { arr1: parsed_arr1.value, arr2: parsed_arr2.value, arr3: parsed_arr3.value, arr4: parsed_arr4.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReservedKeywords
export function serialize_ReservedKeywords(obj: ReservedKeywords): string {
  const serialized = ((value: { object?: string; val?: string; var?: string }): unknown => {
    return {
      object: ((value: string | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["object"]),
      val: ((value: string | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["val"]),
      var: ((value: string | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value;
      })(value["var"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReservedKeywords(str: string): ReservedKeywords | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          object?: string;
          val?: string;
          var?: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_object = (() => {
      if (!hasProp(value, "object")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["object"]);
    })();
    if (!parsed_object.success) {
      return { success: false };
    }
    const parsed_val = (() => {
      if (!hasProp(value, "val")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["val"]);
    })();
    if (!parsed_val.success) {
      return { success: false };
    }
    const parsed_var = (() => {
      if (!hasProp(value, "var")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["var"]);
    })();
    if (!parsed_var.success) {
      return { success: false };
    }
    return { success: true, value: { object: parsed_object.value, val: parsed_val.value, var: parsed_var.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// StringArrayContainer
export function serialize_StringArrayContainer(obj: StringArrayContainer): string {
  const serialized = ((value: {
    arr1: string[];
    arr2: string[] | null;
    arr3: (string | null)[];
    arr4: (string | null)[] | null;
    arr5?: string[];
    arr6?: string[] | null;
    arr7?: (string | null)[];
    arr8?: (string | null)[] | null;
  }): unknown => {
    return {
      arr1: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["arr1"]),
      arr2: ((value: string[] | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["arr2"]),
      arr3: ((value: (string | null)[]): unknown => {
        return value.map((value: string | null): unknown => {
          if (value === null) {
            return null;
          }
          return value;
        });
      })(value["arr3"]),
      arr4: ((value: (string | null)[] | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.map((value: string | null): unknown => {
          if (value === null) {
            return null;
          }
          return value;
        });
      })(value["arr4"]),
      arr5: ((value: string[] | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["arr5"]),
      arr6: ((value: string[] | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["arr6"]),
      arr7: ((value: (string | null)[] | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        return value.map((value: string | null): unknown => {
          if (value === null) {
            return null;
          }
          return value;
        });
      })(value["arr7"]),
      arr8: ((value: (string | null)[] | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value.map((value: string | null): unknown => {
          if (value === null) {
            return null;
          }
          return value;
        });
      })(value["arr8"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_StringArrayContainer(str: string): StringArrayContainer | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr1: string[];
          arr2: string[] | null;
          arr3: (string | null)[];
          arr4: (string | null)[] | null;
          arr5?: string[];
          arr6?: string[] | null;
          arr7?: (string | null)[];
          arr8?: (string | null)[] | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr1")) {
      return { success: false };
    }
    const parsed_arr1 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr1"]);
    })();
    if (!parsed_arr1.success) {
      return { success: false };
    }
    if (!hasProp(value, "arr2")) {
      return { success: false };
    }
    const parsed_arr2 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr2"]);
    })();
    if (!parsed_arr2.success) {
      return { success: false };
    }
    if (!hasProp(value, "arr3")) {
      return { success: false };
    }
    const parsed_arr3 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: (string | null)[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string | null } => {
          if (value === null) {
            return { success: true, value: null };
          }
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr3"]);
    })();
    if (!parsed_arr3.success) {
      return { success: false };
    }
    if (!hasProp(value, "arr4")) {
      return { success: false };
    }
    const parsed_arr4 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: (string | null)[] | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string | null } => {
          if (value === null) {
            return { success: true, value: null };
          }
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr4"]);
    })();
    if (!parsed_arr4.success) {
      return { success: false };
    }
    const parsed_arr5 = (() => {
      if (!hasProp(value, "arr5")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string[] | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr5"]);
    })();
    if (!parsed_arr5.success) {
      return { success: false };
    }
    const parsed_arr6 = (() => {
      if (!hasProp(value, "arr6")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: string[] | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr6"]);
    })();
    if (!parsed_arr6.success) {
      return { success: false };
    }
    const parsed_arr7 = (() => {
      if (!hasProp(value, "arr7")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: (string | null)[] | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string | null } => {
          if (value === null) {
            return { success: true, value: null };
          }
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr7"]);
    })();
    if (!parsed_arr7.success) {
      return { success: false };
    }
    const parsed_arr8 = (() => {
      if (!hasProp(value, "arr8")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: (string | null)[] | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string | null } => {
          if (value === null) {
            return { success: true, value: null };
          }
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr8"]);
    })();
    if (!parsed_arr8.success) {
      return { success: false };
    }
    return { success: true, value: { arr1: parsed_arr1.value, arr2: parsed_arr2.value, arr3: parsed_arr3.value, arr4: parsed_arr4.value, arr5: parsed_arr5.value, arr6: parsed_arr6.value, arr7: parsed_arr7.value, arr8: parsed_arr8.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubModel
export function serialize_SubModel(obj: SubModel): string {
  const serialized = ((value: { intValue: number }): unknown => {
    return {
      intValue: ((value: number): unknown => {
        return value;
      })(value["intValue"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubModel(str: string): SubModel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          intValue: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "intValue")) {
      return { success: false };
    }
    const parsed_intValue = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["intValue"]);
    })();
    if (!parsed_intValue.success) {
      return { success: false };
    }
    return { success: true, value: { intValue: parsed_intValue.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SuperExample
export function serialize_SuperExample(obj: SuperExample): string {
  const serialized = ((value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean }): unknown => {
    switch (value.kind) {
      case "AAA": {
        return {
          kind: "AAA",
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "BBB": {
        return {
          kind: "BBB",
          value: ((value: boolean): unknown => {
            return value;
          })(value["value"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SuperExample(str: string): SuperExample | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "AAA"; value: string } | { kind: "BBB"; value: boolean } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "AAA": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "AAA" as const, ...parsedVariant.value } };
        }
        case "BBB": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  value: boolean;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "BBB" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SuperPartAExample
export function serialize_SuperPartAExample(obj: SuperPartAExample): string {
  const serialized = ((value: { kind: "AAA"; value: string }): unknown => {
    return {
      kind: ((value: "AAA"): unknown => {
        return value;
      })(value["kind"]),
      value: ((value: string): unknown => {
        return value;
      })(value["value"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SuperPartAExample(str: string): SuperPartAExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          kind: "AAA";
          value: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "AAA" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "AAA":
            return { success: true, value: "AAA" };
          default:
            return { success: false };
        }
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    if (!hasProp(value, "value")) {
      return { success: false };
    }
    const parsed_value = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["value"]);
    })();
    if (!parsed_value.success) {
      return { success: false };
    }
    return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SuperPartBExample
export function serialize_SuperPartBExample(obj: SuperPartBExample): string {
  const serialized = ((value: { kind: "BBB"; value: boolean }): unknown => {
    return {
      kind: ((value: "BBB"): unknown => {
        return value;
      })(value["kind"]),
      value: ((value: boolean): unknown => {
        return value;
      })(value["value"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SuperPartBExample(str: string): SuperPartBExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          kind: "BBB";
          value: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "BBB" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "BBB":
            return { success: true, value: "BBB" };
          default:
            return { success: false };
        }
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    if (!hasProp(value, "value")) {
      return { success: false };
    }
    const parsed_value = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["value"]);
    })();
    if (!parsed_value.success) {
      return { success: false };
    }
    return { success: true, value: { kind: parsed_kind.value, value: parsed_value.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TimeStampExample
export function serialize_TimeStampExample(obj: TimeStampExample): string {
  const serialized = ((value: { ts: DateTime<true> }): unknown => {
    return {
      ts: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["ts"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TimeStampExample(str: string): TimeStampExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          ts: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "ts")) {
      return { success: false };
    }
    const parsed_ts = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["ts"]);
    })();
    if (!parsed_ts.success) {
      return { success: false };
    }
    return { success: true, value: { ts: parsed_ts.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// UnNamedEnumInArr
export function serialize_UnNamedEnumInArr(obj: UnNamedEnumInArr): string {
  const serialized = ((value: { arr?: (("FOO" | "BAR") | null)[] | null }): unknown => {
    return {
      arr: ((value: (("FOO" | "BAR") | null)[] | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value.map((value: ("FOO" | "BAR") | null): unknown => {
          if (value === null) {
            return null;
          }
          return value;
        });
      })(value["arr"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_UnNamedEnumInArr(str: string): UnNamedEnumInArr | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr?: (("FOO" | "BAR") | null)[] | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_arr = (() => {
      if (!hasProp(value, "arr")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: (("FOO" | "BAR") | null)[] | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ("FOO" | "BAR") | null } => {
          if (value === null) {
            return { success: true, value: null };
          }
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FOO":
              return { success: true, value: "FOO" };
            case "BAR":
              return { success: true, value: "BAR" };
            default:
              return { success: false };
          }
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr"]);
    })();
    if (!parsed_arr.success) {
      return { success: false };
    }
    return { success: true, value: { arr: parsed_arr.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// UnnamedEnumInObj
export function serialize_UnnamedEnumInObj(obj: UnnamedEnumInObj): string {
  const serialized = ((value: { enumvalue?: ("FOO" | "BAR") | null }): unknown => {
    return {
      enumvalue: ((value: ("FOO" | "BAR") | null | undefined): unknown => {
        if (value === undefined) {
          return undefined;
        }
        if (value === null) {
          return null;
        }
        return value;
      })(value["enumvalue"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_UnnamedEnumInObj(str: string): UnnamedEnumInObj | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          enumvalue?: ("FOO" | "BAR") | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    const parsed_enumvalue = (() => {
      if (!hasProp(value, "enumvalue")) {
        return { success: true, value: undefined };
      }
      return ((value: unknown): { success: false } | { success: true; value: ("FOO" | "BAR") | null | undefined } => {
        if (value === undefined) {
          return { success: true, value: undefined };
        }
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "FOO":
            return { success: true, value: "FOO" };
          case "BAR":
            return { success: true, value: "BAR" };
          default:
            return { success: false };
        }
      })(value["enumvalue"]);
    })();
    if (!parsed_enumvalue.success) {
      return { success: false };
    }
    return { success: true, value: { enumvalue: parsed_enumvalue.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// UriExample
export function serialize_UriExample(obj: UriExample): string {
  const serialized = ((value: { url: string }): unknown => {
    return {
      url: ((value: string): unknown => {
        return value;
      })(value["url"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_UriExample(str: string): UriExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          url: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "url")) {
      return { success: false };
    }
    const parsed_url = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        try {
          new URL(value);
        } catch (ex) {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["url"]);
    })();
    if (!parsed_url.success) {
      return { success: false };
    }
    return { success: true, value: { url: parsed_url.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// UrlExample
export function serialize_UrlExample(obj: UrlExample): string {
  const serialized = ((value: { url: string }): unknown => {
    return {
      url: ((value: string): unknown => {
        return value;
      })(value["url"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_UrlExample(str: string): UrlExample | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          url: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "url")) {
      return { success: false };
    }
    const parsed_url = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        try {
          new URL(value);
        } catch (ex) {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["url"]);
    })();
    if (!parsed_url.success) {
      return { success: false };
    }
    return { success: true, value: { url: parsed_url.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}
