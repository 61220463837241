import { type DeepReadonly, defineComponent, onMounted, type PropType } from "vue";
import type { ChannelMetadata, ContentMetadata, MIABOverviewPageProps, PageInfo } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { createStore, type Store } from "@/common/storeUtils";
import { getPopularSummaries } from "@generated/api/mIABControllerApi";
import { LoadingComponent } from "@/components/search/LoadingComponent";
import { GaButton } from "@/components/general/GaButton";
import { GaChip } from "@/components/general/GaChip";
import ContentCard from "@utils/vue-migration/component/cards/ContentCard.vue";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { NoResults } from "@/components/miab/overviewPageComponents/NoResults";

type LoadingState = {
  kind: "LOADING";
};

type ErrorState = {
  kind: "ERROR";
};

type IdleState = {
  kind: "IDLE";
  miabsNative: ContentMetadata[];
  miabEnglish: ContentMetadata[];
};

type SoftFetchState = {
  kind: "SOFTFETCH";
  miabsNative: ContentMetadata[];
  miabEnglish: ContentMetadata[];
};

type MIABOverviewPageState = (IdleState | LoadingState | ErrorState | SoftFetchState) & {
  pagingNative: PageInfo;
  pagingEnglish: PageInfo;
  selectedChannel: bigint | null;
};

type StateKind = "LOADING" | "ERROR" | "IDLE" | "SOFTFETCH";

async function fetchSummaries(store: Store<MIABOverviewPageState>, softFetch?: boolean): Promise<void> {
  const unpackedStore = store.unpackUnion();
  if (softFetch === true && unpackedStore.kind === "IDLE") {
    store.set({
      ...unpackedStore.store.get(),
      kind: "SOFTFETCH",
    });
  } else {
    store.set({
      ...store.get(),
      kind: "LOADING",
    });
  }
  try {
    const result = await getPopularSummaries({ pageNative: store.get().pagingNative.page, pageEnglish: store.get().pagingEnglish.page, selectedChannel: store.get().selectedChannel });
    store.set({
      ...store.get(),
      kind: "IDLE",
      miabsNative: result.miabsNative,
      pagingNative: result.pagingNative,
      pagingEnglish: result.pagingEnglish,
      miabEnglish: result.miabsEnglish,
    });
  } catch (_) {
    store.set({
      ...unpackedStore.store.get(),
      kind: "ERROR",
    });
  }
}

async function loadMore(store: Store<MIABOverviewPageState>, pageStore: Store<number>): Promise<void> {
  pageStore.set(pageStore.get() + 1);
  await fetchSummaries(store, true);
}

async function selectChannel(channel: bigint | null, store: Store<MIABOverviewPageState>): Promise<void> {
  store.sub("selectedChannel").set(channel);
  await fetchSummaries(store);
}

function Error(): GaVueComponent {
  const { t } = useGaContext();
  return (
    <div>
      <h2 class="h3">{t("miab:overviewPage.loadingError.title")}</h2>
      <p>{t("miab:page.loadingError.description")}</p>
    </div>
  );
}

function SkillPebbles(props: { channels: ChannelMetadata[]; selectChannel: (id: bigint | null) => void; selectedChannel: bigint | null }): GaVueComponent {
  const { t } = useGaContext();
  return (
    <div class="miab-skills">
      <GaChip changeHandler={() => props.selectChannel(null)} active={props.selectedChannel === null} type="radio">
        {t("miab:overviewPage.filter.all")}
      </GaChip>
      {props.channels.map((channel) => (
        <GaChip active={props.selectedChannel === channel.channelId} changeHandler={() => props.selectChannel(channel.channelId)} type="radio">
          {channel.title}
        </GaChip>
      ))}
    </div>
  );
}

function ResultGrid(props: { title: string; items: DeepReadonly<ContentMetadata[]>; paging: DeepReadonly<PageInfo>; loadMore: () => void; kind: StateKind }): GaVueComponent {
  const { t } = useGaContext();
  return (
    <>
      <h2 class="mb-4">{props.title}</h2>
      <div class="miab-overview__grid">
        {props.items.length > 0 ? (
          <>
            {props.items.map((miab) => (
              <ContentCard content={miab} layout="vertical" />
            ))}
          </>
        ) : (
          <NoResults />
        )}
      </div>
      <div class="mb-4 mt-4">
        {props.paging.totalCount > props.paging.pageSize ? (
          <div class="d-flex justify-content-center">
            <GaButton onClick={props.loadMore}>
              {t("miab:overviewPage.paging.loadMore")} {props.kind === "SOFTFETCH" ? <div class="spinner-border spinner-border-sm"></div> : null}
            </GaButton>
          </div>
        ) : null}
      </div>
    </>
  );
}

function Content(props: { store: Store<MIABOverviewPageState>; pageProps: MIABOverviewPageProps }): GaVueComponent {
  const { t, currentLanguage } = useGaContext();
  const unpackedStore = props.store.unpackUnion();
  return (
    <>
      <div class="mb-4 mt-4">
        <h1>{t("miab:overviewPage.title")}</h1>
        <p class="lead mb-5">{t("miab:overviewPage.description")}</p>
      </div>
      <div class="mb-5">
        <SkillPebbles channels={props.pageProps.channels} selectedChannel={props.store.sub("selectedChannel").get()} selectChannel={(channel) => selectChannel(channel, props.store)} />
      </div>
      {unpackedStore.kind === "LOADING" ? (
        <LoadingComponent items={24} perRow={3} itemHeight={"12.8125rem"} withTitle={true} />
      ) : unpackedStore.kind === "IDLE" || unpackedStore.kind === "SOFTFETCH" ? (
        <>
          {unpackedStore.store.sub("miabsNative").get().length > 0 ? (
            <div class="mb-5">
              <ResultGrid
                title={t("miab:overviewPage.subtitle") ?? ""}
                items={unpackedStore.store.sub("miabsNative").get()}
                paging={unpackedStore.store.sub("pagingNative").get()}
                loadMore={() => loadMore(props.store, props.store.sub("pagingNative").sub("page"))}
                kind={unpackedStore.kind}
              />
            </div>
          ) : null}
          {currentLanguage() !== "en" && props.store.get().pagingNative.totalCount <= props.store.get().pagingNative.pageSize ? (
            <>
              <ResultGrid
                title={t("miab:overviewPage.subtitle.notEn") ?? "NOT FOUND"}
                items={unpackedStore.store.sub("miabEnglish").get()}
                paging={unpackedStore.store.sub("pagingEnglish").get()}
                loadMore={() => loadMore(props.store, props.store.sub("pagingEnglish").sub("page"))}
                kind={unpackedStore.kind}
              />
            </>
          ) : null}
        </>
      ) : (
        <Error />
      )}
    </>
  );
}

export const MIABOverviewPage = defineComponent({
  props: {
    miabOverviewPageProps: {
      type: Object as PropType<MIABOverviewPageProps>,
      required: true,
    },
  },
  setup: (props) => {
    const store = createStore<MIABOverviewPageState>({
      kind: "LOADING",
      pagingNative: {
        page: 0,
        totalCount: 0,
        pageSize: 0,
      },
      pagingEnglish: {
        page: 0,
        totalCount: 0,
        pageSize: 0,
      },
      selectedChannel: null,
    });
    onMounted(async () => {
      await fetchSummaries(store);
    });
    return () => <Content store={store} pageProps={props.miabOverviewPageProps} />;
  },
});
