import { ensureNonNull } from "@utils/assertion";
import { type DateFormattingDemoProps } from "@newgenerated/shared/schema";
import { serialize_DateFormattingDemoProps, deserialize_DateFormattingDemoProps } from "@newgenerated/shared/mapper";

export { type DateFormattingDemoProps } from "@newgenerated/shared/schema";
export const parseDateFormattingDemoProps = (data: unknown): DateFormattingDemoProps => {
  return ensureNonNull(deserialize_DateFormattingDemoProps(JSON.stringify(data)));
};
export const renderDateFormattingDemoProps = (data: DateFormattingDemoProps): any => {
  return JSON.parse(serialize_DateFormattingDemoProps(data));
};
